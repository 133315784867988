<script setup>
  import axios from 'axios';

  import { onMounted, ref } from 'vue';
  import { useRouter } from 'vue-router';

  import HeaderComponent from '@/components/Layout/HeaderComponent.vue';
  import FooterComponent from '@/components/Layout/FooterComponent.vue';

  axios.defaults.withCredentials = true;


  const loading = ref(true);

  const isLargeScreen = ref(window.innerWidth > 768);

  const router = useRouter();

  // State variables
  const user = ref(null);

  const expandedIndex = ref(null);
  const moreInfoIsShown = ref(false);

  const competitionDetails = ref(null);

  // Contestants data
  const selfContestant = ref(null);
  const contestants = ref(null);

  // Methods
  const toggleAchievements = (index) => {
    expandedIndex.value = expandedIndex.value === index ? null : index;
  };

  const computeDaysLeft = (endDate) => {
    const today = new Date();
    const end = new Date(endDate);
    
    // Calculate difference in time
    const differenceInTime = end - today;

    // Calculate difference in days
    const differenceInDays = Math.ceil(differenceInTime / (1000 * 60 * 60 * 24));

    return differenceInDays > 0 ? differenceInDays : 0; // Ensure it doesn't show negative numbers
  }

  const getCompetitionDetails = async () => {
    
    // Get the competition URL slug from the URL to fetch details
    const competitionUrlSlug = window.location.pathname.split('/').pop();

    // Remove the URI encoding
    const competitionUrlSlugDecoded = decodeURIComponent(competitionUrlSlug);

    // Define the payload data
    const inputData = {
      competition_url_slug: competitionUrlSlugDecoded
    };

    // Make the API call
    try {
      const competition = await axios.post(
        `${process.env.VUE_APP_BACKEND_API_ROUTE}/get-competition/`, 
        inputData,
        {
          headers: {
              'Content-Type': 'application/json'
          },
          withCredentials: true,
        })

      if (competition.status === 200) {
        competitionDetails.value = competition.data['competition_details'];
      } else {
        console.error('Error fetching competition details:', competition);
      }

    } catch (error) {
      console.error(
        'There was an error trying to get competition details!', 
        error
      );
    }
  };

  const updateSelfContestantProgress = async () => {
      
      const competitionId = competitionDetails.value.id
  
      // Define the payload data
      const inputData = {
        competition_id: competitionId
      };
  
      // Make the API call
      try {
        const selfContestantProgressRes = await axios.post(
          `${process.env.VUE_APP_BACKEND_API_ROUTE}/update-self-contestant-progress/`, 
          inputData,
          {
            headers: {
                'Content-Type': 'application/json'
            },
            withCredentials: true,
          })
  
        if (selfContestantProgressRes.status === 200) {
          console.log('selfContestantProgressRes:', selfContestantProgressRes.data);
          const selfContestantProgress = selfContestantProgressRes.data['user_progress'];
          selfContestant.value = selfContestantProgress;
  
        } else {
          console.error('Error updating self contestant progress:', selfContestantProgressRes);
        }
  
      } catch (error) {
        console.error(
          'There was an error trying to update self contestant progress!', 
          error
        );
      }
  }

  const getContestantProgress = async () => {
    
    const competitionId = competitionDetails.value.id

    // Define the payload data
    const inputData = {
      competition_id: competitionId
    };

    // Make the API call
    try {
      const contestantProgressRes = await axios.post(
        `${process.env.VUE_APP_BACKEND_API_ROUTE}/get-contestant-progress/`, 
        inputData,
        {
          headers: {
              'Content-Type': 'application/json'
          },
          withCredentials: true,
        })

      if (contestantProgressRes.status === 200) {
        console.log('contestantProgressRes:', contestantProgressRes.data);
        const allUserProgress = contestantProgressRes.data['all_user_progress'];

        // Sort allUserProgress by chance of winning, descending
        contestants.value = allUserProgress.sort(
          (a, b) => b.chance_of_winning - a.chance_of_winning
        );

      } else {
        console.error('Error getting contestant progress:', contestantProgressRes);
      }

    } catch (error) {
      console.error(
        'There was an error trying to get contestant progress!', 
        error
      );
    }
  };

  const getUser = async () => {
    try {
      const response = await fetch(`${process.env.VUE_APP_BACKEND_API_ROUTE}/user`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        },
        credentials: 'include'  // Necessary for passing of login cookies from backend
      })

      if (response.status === 200) {
        const resJson = await response.json();  // Parse the JSON data from the response
        user.value = resJson.user;
        return;
      }

      if (response.status === 403) {
        router.push('/signup');
      }

    } catch (error) {
      console.error(
        'There was an error trying to get user details!', 
        error
      );
    }
  }

  const showTooltip = (event) => {
      const tooltip = event.currentTarget.querySelector('.hs-tooltip-content');
      tooltip.classList.remove('opacity-0', 'invisible');
      tooltip.classList.add('opacity-100', 'visible');
  }

  const hideTooltip = (event) => {
      const tooltip = event.currentTarget.querySelector('.hs-tooltip-content');
      tooltip.classList.remove('opacity-100', 'visible');
      tooltip.classList.add('opacity-0', 'invisible');
  }

  const updateAllContestantProgress = async () => {
      
      const competitionId = competitionDetails.value.id
  
      // Define the payload data
      const inputData = {
        competition_id: competitionId
      };
  
      // Make the API call
      try {
        const contestantProgressRes = await axios.post(
          `${process.env.VUE_APP_BACKEND_API_ROUTE}/update-all-contestant-progress/`, 
          inputData,
          {
            headers: {
                'Content-Type': 'application/json'
            },
            withCredentials: true,
          })
  
        if (contestantProgressRes.status === 200) {
          console.log('contestantProgressResFromAll:', contestantProgressRes.data);
          const allUserProgress = contestantProgressRes.data['all_user_progress'];

          // Sort allUserProgress by chance of winning, descending
          contestants.value = allUserProgress.sort(
            (a, b) => b.chance_of_winning - a.chance_of_winning
          );
  
        } else {
          console.error('Error updating all contestant progress:', contestantProgressRes);
        }
  
      } catch (error) {
        console.error(
          'There was an error trying update all contestant progress!', 
          error
        );
      }
  }

  onMounted(async () => {
    loading.value = true;

    await getCompetitionDetails();
    await getUser();

    if (user.value.xbox_gamertag == 'GPADMINMAVRAM') {
      await updateAllContestantProgress();
    } else {
      await updateSelfContestantProgress();
      await getContestantProgress();
    }

    loading.value = false;
  })



</script>

<template>
  <body class="bg-slate-900 min-h-full">
    <div class="max-w-[50rem] flex flex-col mx-auto w-full min-h-screen">
      <HeaderComponent />
      <div v-if="loading">
          <div class="text-center text-xl font-bold">Hold on - Our Competition Score Tally Minions are Hard at Work</div>
          <div class="flex justify-center items-center mt-10">
            <svg class="animate-spin h-10 w-10 text-primary" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
              <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V4a10 10 0 00-10 10h2zm2 8a8 8 0 018-8h2a10 10 0 00-10-10v2zm8 2a8 8 0 01-8-8H4a10 10 0 0010 10v-2z"></path>
            </svg>
          </div>
        </div>
      <div v-else-if="!loading && competitionDetails">
        <div class="text-xl font-bold"><h1>{{ competitionDetails.game_title }}</h1></div>
        <div class="text-center mt-3">
          <button @click="moreInfoIsShown = !moreInfoIsShown" type="button" class="my-3 mx-1 inline-flex justify-center items-center rounded-md border border-primary font-semibold text-white focus:outline-none focus:ring-2 focus:ring-offset-2 transition-all text-sm p-2 ring-offset-gray-800">
              {{ moreInfoIsShown ? 'Less' : 'More' }} Info
          </button>
        </div>
        <div v-if="moreInfoIsShown" class="flex flex-col space-y-4">
          <div class="text-left bg-slate-800 p-4 rounded-lg my-1">
            <img :src=competitionDetails.image_url alt="competition image" class="rounded-lg">
            <h2 class="font-bold mt-2 break-words">{{ competitionDetails.title }}</h2>
            <div class="my-2 break-words">{{ competitionDetails.description }}</div>
            <div class="my-2"><font-awesome-icon class="text-amber-300" :icon="['fas', 'trophy']" /> <span class="text-primary font-bold">Chance to Win: </span>{{ competitionDetails.chance_to_win_info }}</div>
            <div class="my-2"><span class="text-primary font-bold">Useful Tips: </span>{{ competitionDetails.tips_info }}</div>
            <div><span class="text-red-400 font-bold">IMPORTANT: </span>{{ competitionDetails.important_info }}</div>
            <div class="flex items-center justify-between mt-2">
              <div class="text-gray-300 text-xs text-left">Number of Participants: {{ competitionDetails.num_participants }}</div>
              <div class="text-gray-300 text-xs text-right">{{ computeDaysLeft(competitionDetails.end_date) }} days left</div>
            </div>
          </div>
        </div>
        <div class="container mx-auto p-4">
          <!-- Header Row -->
          <div class="bg-gray-400 shadow-lg rounded-lg p-4 mb-2">
            <div class="flex items-center justify-between space-x-4">
              <div class="text-xs sm:text-lg font-semibold text-gray-700 w-1/12"></div>
              <div class="text-xs sm:text-lg font-semibold text-gray-700 text-left w-4/12">Player</div>
              <div class="text-xs sm:text-lg font-semibold text-gray-700 w-2/12">Progress</div>
              <div class="text-xs sm:text-lg font-semibold text-gray-700 w-3/12">{{ isLargeScreen ? 'Chance to Win' : '% to Win' }}</div>
              <div class="text-xs sm:text-lg font-semibold text-gray-700 w-2/12">{{ isLargeScreen ? '' : 'More Info' }}</div> <!-- Blank column for button -->
            </div>
          </div>

          <!-- Contestant Rows -->
          <div 
            v-for="(contestant, index) in contestants" 
            :key="index" 
            class="bg-gray-300 shadow-lg rounded-lg mb-2 p-4"
          >
            <!-- First Row -->
            <div class="flex items-center justify-between space-x-4">
              <div class="text-sm sm:text-lg font-semibold text-gray-700 w-1/12">#{{ index + 1 }}</div>
              <div class="text-xs sm:text-lg font-semibold text-gray-700 h-15 overflow-y-auto text-left w-4/12">
                <router-link :to="`/social/profiles/${contestant.user_xbox_gamertag}`" class="ml-2 hover:underline">
                  <span class="text-secondary">{{ contestant.user_xbox_gamertag }}</span>
                </router-link>
              </div>
              <div class="text-sm sm:text-lg text-gray-600 w-2/12">{{ (contestant.game_progress_percentage.toFixed(0)) }}%</div>
              <div class="text-sm sm:text-lg text-gray-600 w-3/12">
                <span>
                  {{ Math.abs(contestant.chance_of_winning * 100).toFixed(1) }}%
                </span>
                <span v-if="contestant.starting_score > 0" class="text-xs">
                  <span class="hs-tooltip inline-block text-red-400 text-xs">
                    <button @mouseover="showTooltip" @mouseout="hideTooltip" type="button" class="hs-tooltip-toggle">
                      <svg class="inline w-4 h-4 mb-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                      </svg>
                      <span ref="tooltipContent" class="hs-tooltip-content opacity-0 transition-opacity inline-block absolute invisible z-10 w-40 py-1 px-2 text-left text-xs font-bold bg-secondary border-green-500" style="border: 1px solid green; background-color: #1a202c; color: white; border-radius: 4px;" role="tooltip">
                        Your chance to win is reduced because you had a head start in the competition by playing this game in the past. Your starting score was {{ (contestant.starting_score).toFixed(1) }} instead of starting from 0 like others did.
                      </span>
                    </button>
                  </span>
                </span>
              </div>
              <div class="text-sm sm:text-lg text-gray-600 w-2/12">
                <button 
                  v-if="isLargeScreen"
                  class="my-3 mx-1 text-xs text-gray-700 inline-flex justify-center items-center rounded-md border border-gray-700 font-semibold transition-all p-2" 
                  @click="toggleAchievements(index)"
                >
                  {{ expandedIndex === index ? 'Hide Achievements' : 'Show Achievements' }}
                </button>
                <button 
                  v-else
                  class="my-3 mx-1 text-xs text-gray-700 inline-flex justify-center items-center font-semibold transition-all p-2" 
                  @click="toggleAchievements(index)"
                >
                  <font-awesome-icon :icon="['fas', 'trophy']" />
                </button>
              </div>
            </div>

            <!-- Second Row (Expandable Achievements) -->
            <div v-if="expandedIndex === index" class="mt-4 bg-gray-200 rounded-lg p-4">
              <div 
                v-for="(achievement, aIndex) in contestant.user_achievements" 
                :key="aIndex" 
                class="flex items-center justify-between space-x-4 py-2 border-b border-gray-600 last:border-none"
              >
                <div class="w-3/4 flex items-center space-x-2">
                  <img 
                    :src="achievement.image_url" 
                    alt="Achievement" 
                    class="w-10 h-10 rounded-lg"
                  />
                  <div 
                    class="text-xs sm:text-base font-bold h-6 overflow-y-auto text-left"
                    :class="{
                      'text-gray-800': Math.abs(achievement.rarity_percentage) > 60 && Math.abs(achievement.rarity_percentage) <= 100,  // Common
                      'text-green-500': Math.abs(achievement.rarity_percentage) > 30 && Math.abs(achievement.rarity_percentage) <= 60,  // Rare
                      'text-purple-500': Math.abs(achievement.rarity_percentage) > 10 && Math.abs(achievement.rarity_percentage) <= 30,  // Very Rare
                      'text-yellow-500': Math.abs(achievement.rarity_percentage) > 0 && Math.abs(achievement.rarity_percentage) <= 10  // Legendary
                    }"
                  >
                    {{ achievement.name }}<span v-if="isLargeScreen" class="text-xs text-gray-700"> - {{ achievement.description }}</span>
                  </div>
                </div>
                <div v-if="contestant.total_competition_score_delta !== 0 && contestant.chance_of_winning !== 0 && contestant.current_score_delta > 2 && achievement.rarity_percentage > 0" class="text-xs sm:text-base w-1/4 text-left text-gray-600">
                  Chance Boost +<span class="font-bold">
                    {{ ((10 * (1 / achievement.rarity_percentage)) / contestant.total_competition_score_delta * 100).toFixed(2) }}%
                  </span>
                </div>
                <div v-else class="text-xs sm:text-base w-1/4 text-left text-gray-600">Not Counted</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FooterComponent />
    </div>

  </body>
</template>

<style scoped>

</style>
