<script setup>

    import axios from 'axios';

    import { useRouter } from 'vue-router';
    import { onMounted, ref } from 'vue';
    import { useStore } from 'vuex';

    import HeaderComponent from '@/components/Layout/HeaderComponent.vue';
    import FooterComponent from '@/components/Layout/FooterComponent.vue';

    const router = useRouter();
    const store = useStore();

    const user = ref(null);
    const loading = ref(false);

    const leaderboard = ref([]);


    onMounted(async () => {
        loading.value = true;

        // Wait for 3 seconds
        await new Promise(resolve => setTimeout(resolve, 3000));

        await getUser();

        await getLeaderboard();

        loading.value = false;
    })

    const getUser = async () => {
        // Retrieving the user
        try {
            const response = await fetch(`${process.env.VUE_APP_BACKEND_API_ROUTE}/user`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include'  // Necessary for passing of login cookies from backend
            })

            // Check for 403 error
            if (response.status === 403) {
            await store.dispatch('setAuth', false);
            await navigateToLoginPage();
            }

            const content = await response.json();

            if (content.detail === 'Unauthenticated!') {
            await store.dispatch('setAuth', false);
            await navigateToLoginPage();
            } else {
            await store.dispatch('setAuth', true);
            user.value = content.user;
            }
        } catch (e) {
            await store.dispatch('setAuth', false);
            await navigateToLoginPage();
        }
    }

    const navigateToLoginPage = () => {
        router.push('/login');
    }

    const getLeaderboard = async () => {
        try {

        const inputData = {
            leaderboard_type: 'top game karma points',
        };

        const response = await axios.post(
            `${process.env.VUE_APP_BACKEND_API_ROUTE}/leaderboards/`, 
                inputData,
            {
                headers: {
                    'Content-Type': 'application/json'
                },
                withCredentials: true,
            })

        if (response.status === 200 && response.data) {
            leaderboard.value = response.data.leaderboard;
        } else {
            console.error('Could not retrieve the leaderboard.', response);
            return false;
        }

        } catch (error) {
            console.error('Could not retrieve the leaderboard.', error);
            return false;
        }
    }

</script>

<template>
  <body class="bg-slate-900 min-h-full">
    <div class="max-w-[50rem] flex flex-col mx-auto w-full min-h-screen">
      <HeaderComponent />
      <div>
        <div class="h1 text-xl font-bold mb-8">Leaderboards</div>
        <div v-if="loading" class="mt-16">
            <div class="animate-spin inline-block size-6 w-10 h-10 border-[3px] border-current border-t-transparent rounded-full text-white" role="status" aria-label="loading">
            <span class="sr-only">Loading...</span>
            </div>
        </div>
        <div v-else-if="!loading && leaderboard.length > 0" class="mx-10">
            <div class="flex flex-col">
                <div class="flex justify-between items-center mb-2">
                    <div class="text-primary font-medium">Username</div>
                    <div class="text-primary font-medium">GameKarma Points</div>
                </div>
                <div v-for="(userInfo, index) in leaderboard" :key="index" class="flex justify-between items-center mb-2">
                    <div>
                        <router-link :to="`/social/profiles/${userInfo.username}`" class="ml-2 hover:underline">
                            <span class="text-white">{{ userInfo.username }}</span>
                        </router-link>
                    </div>
                    <div>{{ userInfo.game_karma_points }}</div>
                </div>
            </div>
        </div>
      </div>
      <FooterComponent />
    </div>
  </body>
</template>

<style scoped>

</style>
