<template>
  <body class="bg-slate-900 min-h-full">
    <div class="max-w-[50rem] flex flex-col mx-auto w-full min-h-screen">
      <HeaderComponent />
      <div v-if="!loading && authenticated" ref="scrollableDiv" class="h-[84vh] max-h-[84vh] overflow-y-auto box-border scrollbar-thin scrollbar-thumb-gray scrollbar-track-dark">
        <div>
          <div class="mx-3">
            <div class="text-left pb-2">
              Search for games below. If you don't remember the exact name, just write part of it. Interested in a game? Look for the following icons 
              (<font-awesome-icon class="text-white px-1" icon="fa-solid fa-gift" /> - interested, 
              <font-awesome-icon class="text-white px-1" icon="fa-solid fa-gamepad" /> - playing,
              <font-awesome-icon class="text-white px-1" icon="fa-solid fa-hourglass-half" /> - played,
              <font-awesome-icon class="text-white px-1" icon="fa-solid fa-check-to-slot" /> - completed)   
              and select them to add the games to your game <a class="text-primary decoration-2 hover:underline font-medium cursor-pointer" @click="navigateToGamingDashboardPage">dashboard</a>.
            </div>
            <form @submit.prevent="searchGames(gameTitleSearch)">
                <div class="flex rounded-lg shadow-sm">
                    <input type="text" id="hs-search-box-with-loading-4" v-model="gameTitleSearch" name="hs-search-box-with-loading-4" class="py-3 px-4 block w-full shadow-sm rounded-s-lg text-sm focus:z-10 focus:border-green-500 disabled:opacity-50 disabled:pointer-events-none bg-slate-700 border-gray-700 text-gray-100 focus:ring-gray-600" placeholder="e.g. Starfield">
                    <button class="py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-e-md border border-transparent bg-primary text-white hover:bg-green-700 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:ring-1 focus:ring-gray-600">
                        <span v-if="searchLoading" class="animate-spin inline-block w-4 h-4 border-[3px] border-current border-t-transparent text-white rounded-full" role="status" aria-label="loading">
                            <span class="sr-only">Loading...</span>
                        </span>
                        Search
                    </button>
                </div>
            </form>
          </div> 
          <div>
            <button type="button" class="px-4 mt-4 justify-center items-center gap-2 rounded-md border border-gray-400 font-semibold bg-gray-800 text-white focus:outline-none focus:ring-2 focus:ring-offset-2 transition-all text-xs p-2 sm:p-4 ring-offset-gray-800" data-hs-overlay="#hs-allgames-config-modal">
              <div class="inline-flex"><font-awesome-icon icon="fa-solid fa-gear" />&nbsp;Search Preferences</div>
            </button>
            <div class="hs-dropdown relative inline-flex bg-gray-800 border-gray-400 ml-3">
              <button id="hs-dropdown-basic" type="button" class="hs-dropdown-toggle px-4 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border shadow-sm disabled:opacity-50 disabled:pointer-events-none text-white hover:bg-gray-800 focus:outline-none focus:ring-1 focus:ring-gray-600 border-gray-400 py-1 sm:py-3">
                Sort by
                <svg class="hs-dropdown-open:rotate-180 size-4 text-white" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="m6 9 6 6 6-6"/></svg>
              </button>

              <div class="hs-dropdown-menu transition-[opacity,margin] duration hs-dropdown-open:opacity-100 opacity-0 w-56 hidden z-10 mt-2 min-w-60 shadow-md rounded-lg p-2 bg-gray-800 border border-gray-700 divide-gray-700" aria-labelledby="hs-dropdown-basic">
                <a @click="sortBy('IGN Score', 'High to Low')" :class="['flex items-center gap-x-3.5 py-2 px-3 rounded-lg text-sm focus:outline-none text-white hover:bg-gray-700 hover:text-gray-300 focus:bg-gray-700', sortCategory === 'IGN Score' && sortDirection == 'High to Low' ? 'text-primary hover:text-green-600' : 'text-white']" href="#">
                  IGN Score (High to Low)
                </a>
                <a @click="sortBy('IGN Score', 'Low to High')" :class="['flex items-center gap-x-3.5 py-2 px-3 rounded-lg text-sm focus:outline-none text-white hover:bg-gray-700 hover:text-gray-300 focus:bg-gray-700', sortCategory === 'IGN Score' && sortDirection == 'Low to High' ? 'text-primary hover:text-green-600' : 'text-white']" href="#">
                  IGN Score (Low to High)
                </a>
                <a @click="sortBy('Game Length', 'High to Low')" :class="['flex items-center gap-x-3.5 py-2 px-3 rounded-lg text-sm focus:outline-none text-white hover:bg-gray-700 hover:text-gray-300 focus:bg-gray-700', sortCategory === 'Game Length' && sortDirection == 'High to Low' ? 'text-primary hover:text-green-600' : 'text-white']" href="#">
                  Game Length (High to Low)
                </a>
                <a @click="sortBy('Game Length', 'Low to High')" :class="['flex items-center gap-x-3.5 py-2 px-3 rounded-lg text-sm focus:outline-none text-white hover:bg-gray-700 hover:text-gray-300 focus:bg-gray-700', sortCategory === 'Game Length' && sortDirection == 'Low to High' ? 'text-primary hover:text-green-600' : 'text-white']" href="#">
                  Game Length (Low to High)
                </a>
                <a @click="sortBy('Release Year', 'High to Low')" :class="['flex items-center gap-x-3.5 py-2 px-3 rounded-lg text-sm focus:outline-none text-white hover:bg-gray-700 hover:text-gray-300 focus:bg-gray-700', sortCategory === 'Release Year' && sortDirection == 'High to Low' ? 'text-primary hover:text-green-600' : 'text-white']" href="#">
                  Release Year (High to Low)
                </a>
                <a @click="sortBy('Release Year', 'Low to High')" :class="['flex items-center gap-x-3.5 py-2 px-3 rounded-lg text-sm focus:outline-none text-white hover:bg-gray-700 hover:text-gray-300 focus:bg-gray-700', sortCategory === 'Release Year' && sortDirection == 'Low to High' ? 'text-primary hover:text-green-600' : 'text-white']" href="#">
                  Release Year (Low to High)
                </a>
                <a @click="sortBy('Game Pass Release Date', 'High to Low')" :class="['flex items-center gap-x-3.5 py-2 px-3 rounded-lg text-sm focus:outline-none text-white hover:bg-gray-700 hover:text-gray-300 focus:bg-gray-700', sortCategory === 'Game Pass Release Date' && sortDirection == 'High to Low' ? 'text-primary hover:text-green-600' : 'text-white']" href="#">
                  GP Rel. Date (High to Low)
                </a>
                <a @click="sortBy('Game Pass Release Date', 'Low to High')" :class="['flex items-center gap-x-3.5 py-2 px-3 rounded-lg text-sm focus:outline-none text-white hover:bg-gray-700 hover:text-gray-300 focus:bg-gray-700', sortCategory === 'Game Pass Release Date' && sortDirection == 'Low to High' ? 'text-primary hover:text-green-600' : 'text-white']" href="#">
                  GP Rel. Date (Low to High)
                </a>
                <a @click="sortBy('Alphabetical', 'Low to High')" :class="['flex items-center gap-x-3.5 py-2 px-3 rounded-lg text-sm focus:outline-none text-white hover:bg-gray-700 hover:text-gray-300 focus:bg-gray-700', sortCategory === 'Alphabetical' ? 'text-primary hover:text-green-500' : 'text-white']" href="#">
                  Alphabetical
                </a>
              </div>
            </div>
          </div>
          <!-- <div v-if="userQuota && !premiumMember" class="text-xs mt-2">
            <span v-if="pickingPreferenceSelected">
              You have <b>{{ userQuota['search_games_pick_preferences_quota'] }}</b> preference customizations left this week, <router-link to="/premium-pricing" class="text-primary hover:underline">Join Premium</router-link> to get unlimited
            </span>
            <span v-else-if="addGameInterestSelected">
              You have <b>{{ userQuota['game_addition_quota'] }}</b> game additions left this week, <router-link to="/premium-pricing" class="text-primary hover:underline">Join Premium</router-link> to get unlimited
            </span>
          </div> -->
          <hr class="my-8">
          <div>
            <div class="text-xl font-bold">All Games</div>
            <div v-if="filteredAndSearchedGames.length === 0" class="mt-2 mx-2 text-left">
              No games found based on your search and/or preference filter criteria. Please change your search or preference filters and try again.
            </div>
            <div v-if="filteredAndSearchedGames.length > 0" class="mt-1 font-bold text-xs mb-2">
              <button v-if="currentPage >= 2" @click="prevPage" :disabled="currentPage <= 1" class="mr-2 text-primary hover:underline">Prev</button>
              <span>Page {{ currentPage }} of {{ totalPages }}</span>
              <button v-if="currentPage < totalPages" @click="nextPage" :disabled="currentPage >= totalPages" class="ml-2 text-primary hover:underline">Next</button>
            </div>
            <div v-if="filteredAndSearchedGames.length > 0" class="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-5 lg:grid-cols-5 mx-4 gap-6 place-items-center">
              <div
                v-for="(game, index) in gamesToDisplay"
                :key="index"
                @click="viewGameDetailsPage(game.xbox_api_id, game.title, $event)"
                class="w-32 relative flex-col border shadow-sm rounded-xl bg-gray-800 border-gray-700 overflow-y-auto scrollbar-thin scrollbar-thumb-gray scrollbar-track-dark hover:border-1 hover:border-[#42b983] hover:shadow-xl hover:shadow-[#42b983]/50 transition-all duration-200"
                >
                  <div v-if="initialLoad">
                    <img class="w-full h-2/5 rounded-t-xl object-cover border-b border-gray-700" :src="game.image_uris[0]" alt="The banner image for the game">
                  </div>
                  <div v-else>
                    <img class="w-full h-2/5 rounded-t-xl object-cover border-b border-gray-700" :src="game.image_loaded ? game.image_uris[0] : loadingImagePlaceholderPath" @load="game.image_loaded = true" alt="The banner image for the game">
                  </div>
                  <div class="py-2 max-h-32 overflow-y-auto scrollbar-thin scrollbar-thumb-gray scrollbar-track-dark">
                      <h3 class="text-xs font-bold text-white pt-2">{{ game.title }}</h3>
                  </div>
                  <div v-if="game.leaving_soon" class="text-xs text-primary font-bold underline pb-1">
                    Leaving {{ game.leaving_soon_date }}
                  </div>
                  <div v-if="isRecentlyReleased(game)" class="text-xs text-primary font-bold underline pb-1">
                    Just Released
                  </div>
                  <div v-if="game.left_gamepass" class="text-xs text-primary font-bold underline pb-1">
                    Left Game Pass
                  </div>
                  <hr class="mb-1" />
                  <div class="flex items-center justify-between text-white text-left px-1">
                    <div @click.stop="navigateToMetacriticUrl(game)" class="cursor-pointer">
                      <img class="w-8 h-8" src="../assets/metacritic.png" alt="Metacritic logo">
                    </div>
                    <div @click.stop="navigateToIgnUrl(game)" class="flex items-center cursor-pointer mt-1 ign-score-container text-xs">
                      <img class="w-8 h-8 align-center" src="../assets/ign-score-background.png" alt="IGN score logo which is a red hexagon">
                      <div class="ign-score">{{ game.ign_score }}</div>
                    </div>
                    <div @click.stop="navigateToHowLongToBeatUrl(game)" class="cursor-pointer">
                      <img class="w-8 h-8" src="../assets/howlongtobeat.png" alt="How long to beat logo">
                    </div>
                  </div>
                  <hr class="mt-1" />
                  <div class="px-1 flex justify-center text-center text-xs py-2 flex-col">
                    <span class="text-center">{{ game.ign_all_possible_genres.join(', ') }}</span>
                  </div>
                  <hr />
                  <div class="px-1 flex justify-between text-white text-xs text-left py-2">
                    <span v-if="game.ign_duration_hours">Game length: {{ game.ign_duration_hours }}</span>
                    <span v-else>Game length: --</span>
                  </div>
                  <hr />
                  <div class="px-1 flex justify-center text-white text-xs text-center py-2">
                    <span>
                      Released in 
                      <span v-if="game.original_release_year">{{ game.original_release_year }}</span>
                      <span v-else>--</span>
                    </span>
                  </div>
                  <hr />
                  <div class="px-1 flex text-primary justify-center text-center text-xs py-2 flex-col">
                    <div>
                      <a @click.stop class="hover:underline" :href="game.microsoft_store_url" target="_blank">
                        Save to <font-awesome-icon 
                        class="text-white px-1 hover:text-green-300 cursor-pointer" 
                        :icon="['fab', 'xbox']" 
                      /></a>
                    </div>
                    <div>
                      <a @click="selectGame(game)" data-hs-overlay="#hs-gameprops-modal-allgames" class="cursor-pointer hover:underline" target="_blank">Game Properties</a>
                    </div>
                    <div>
                      <a @click.stop class="hover:underline" :href="game.youtube_review_url" target="_blank">Review Video</a>
                    </div>
                    <div>
                      <a @click.stop class="hover:underline" :href="game.youtube_trailer_url" target="_blank">Trailer Video</a>
                    </div>
                    <div>
                      <a @click.stop class="hover:underline" :href="game.youtube_walkthrough_url" target="_blank">Walkthrough Video</a>
                    </div>
                  </div>
                  <hr />
                  <div class="px-1 flex justify-between text-white text-lg text-left py-2">
                    <font-awesome-icon @click.stop.prevent="addGameInterest('wishlist', game.title)" class="text-white px-1 hover:text-green-300 cursor-pointer" icon="fa-solid fa-gift" />
                    <font-awesome-icon @click.stop.prevent="addGameInterest('currently playing', game.title)" class="text-white px-1 hover:text-green-300 cursor-pointer" icon="fa-solid fa-gamepad" />
                    <font-awesome-icon @click.stop.prevent="addGameInterest('played', game.title)" class="text-white px-1 hover:text-green-300 cursor-pointer" icon="fa-solid fa-hourglass-half" />
                    <font-awesome-icon @click.stop.prevent="addGameInterest('completed', game.title)" class="text-white px-1 hover:text-green-300 cursor-pointer" icon="fa-solid fa-check-to-slot" />
                  </div>
              </div>
            </div>
            <div v-if="filteredAndSearchedGames.length > 0" class="mt-2 font-bold">
              <button v-if="currentPage >= 2" @click="prevPage" :disabled="currentPage <= 1" class="mr-2 text-primary hover:underline">Prev</button>
              <span>Page {{ currentPage }} of {{ totalPages }}</span>
              <button v-if="currentPage < totalPages" @click="nextPage" :disabled="currentPage >= totalPages" class="ml-2 text-primary hover:underline">Next</button>
            </div>
          </div>
        </div>
      </div>

      <!-- NOTIFICATION (STARTS) -->
      <div v-if="notificationPresent" class="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 max-w-xs border rounded-xl shadow-lg bg-gray-800 border-primary-nowhite z-50" role="alert">
        <div class="p-4 relative">
          <button @click="notificationPresent = false" class="absolute top-2 right-2 text-gray-200 hover:text-white">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
          <div class="mb-2 text-center flex justify-center items-center">
            <svg class="flex-shrink-0 h-4 w-4 text-primary mt-0.5" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
            </svg>
          </div>
          <div class="text-left">
            <p class="text-lg text-gray-200 pl-2">{{ notification }}</p>
            <div v-if="isPlayingOrPlayedGame && !notificationLinkPresent" class="mt-3">
              <hr />
              <div class="mt-2 text-lg font-bold">What would you rate this game?</div>
              <div class="flex justify-center align-center mt-2">
                <span class="rate">
                  <i @click="rateGame(gameSelected, 1)">★</i>
                  <i @click="rateGame(gameSelected, 2)">★</i>
                  <i @click="rateGame(gameSelected, 3)">★</i>
                  <i @click="rateGame(gameSelected, 4)">★</i>
                  <i @click="rateGame(gameSelected, 5)">★</i>
                </span>
              </div>
            </div>
            <div v-if="notificationLinkPresent" class="text-lg text-center text-primary mt-2">
                <router-link class="hover:underline" to="/premium-pricing" >Join Premium</router-link>
            </div>
          </div>
        </div>
      </div>
      <!-- NOTIFICATION (ENDS) -->

      <!-- POP-UP MODAL TO ESTABLISH PICKING SETTINGS (STARTS) -->
      <div id="hs-allgames-config-modal" class="hs-overlay hidden w-screen h-full fixed top-0 left-0 z-[60] overflow-x-hidden overflow-y-auto text-center">
        <div class="w-screen h-full hs-overlay-open:opacity-100 hs-overlay-open:duration-500 opacity-0 transition-all p-3">
          <div class="h-full flex flex-col border shadow-sm rounded-xl bg-gray-800 border-gray-700 shadow-slate-700/[.7]">
            <div class="flex justify-between items-center py-3 px-4 border-b border-gray-700">
              <span></span>
              <h3 class="font-bold text-white">
                <font-awesome-icon icon="fa-solid fa-gear" /> Picking Preferences
              </h3>
              <button type="button" class="hs-dropdown-toggle inline-flex flex-shrink-0 justify-center items-center h-8 w-8 rounded-md text-gray-500 hover:text-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 transition-all text-sm focus:ring-gray-700 focus:ring-offset-gray-800" data-hs-overlay="#hs-allgames-config-modal">
                <span class="sr-only">Close</span>
                <svg class="w-3.5 h-3.5" width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0.258206 1.00652C0.351976 0.912791 0.479126 0.860131 0.611706 0.860131C0.744296 0.860131 0.871447 0.912791 0.965207 1.00652L3.61171 3.65302L6.25822 1.00652C6.30432 0.958771 6.35952 0.920671 6.42052 0.894471C6.48152 0.868271 6.54712 0.854471 6.61352 0.853901C6.67992 0.853321 6.74572 0.865971 6.80722 0.891111C6.86862 0.916251 6.92442 0.953381 6.97142 1.00032C7.01832 1.04727 7.05552 1.1031 7.08062 1.16454C7.10572 1.22599 7.11842 1.29183 7.11782 1.35822C7.11722 1.42461 7.10342 1.49022 7.07722 1.55122C7.05102 1.61222 7.01292 1.6674 6.96522 1.71352L4.31871 4.36002L6.96522 7.00648C7.05632 7.10078 7.10672 7.22708 7.10552 7.35818C7.10442 7.48928 7.05182 7.61468 6.95912 7.70738C6.86642 7.80018 6.74102 7.85268 6.60992 7.85388C6.47882 7.85498 6.35252 7.80458 6.25822 7.71348L3.61171 5.06702L0.965207 7.71348C0.870907 7.80458 0.744606 7.85498 0.613506 7.85388C0.482406 7.85268 0.357007 7.80018 0.264297 7.70738C0.171597 7.61468 0.119017 7.48928 0.117877 7.35818C0.116737 7.22708 0.167126 7.10078 0.258206 7.00648L2.90471 4.36002L0.258206 1.71352C0.164476 1.61976 0.111816 1.4926 0.111816 1.36002C0.111816 1.22744 0.164476 1.10028 0.258206 1.00652Z" fill="currentColor"/>
                </svg>
              </button>
            </div>
            <div class="p-4 overflow-y-auto">
              <p class="mt-1 text-center text-white">
                Preferred Genre(s)
              </p>

              <div class="flex justify-center items-center mt-2">
                <!-- First Label -->
                <label
                  :class="{'text-white': !genreSwitchIsChecked, 'text-gray-500': genreSwitchIsChecked}"
                  class="mr-3 text-sm transition-colors duration-300"
                >
                  All Genres Except...
                </label>

                <!-- Toggle Switch -->
                <div @click.prevent="genreSwitchIsChecked = !genreSwitchIsChecked" class="relative">
                  <input
                    type="checkbox"
                    id="toggleSwitch"
                    class="sr-only peer w-full h-full cursor-pointer"
                    v-model="genreSwitchIsChecked"
                  />
                  <div
                    class="block bg-red-500 peer-checked:bg-green-400 w-7 h-4 rounded-full transition-colors duration-300"
                  ></div>
                  <div
                    class="dot absolute left-0.5 top-0.5 bg-white w-3 h-3 rounded-full transition-transform duration-300 ease-in-out peer-checked:transform peer-checked:translate-x-3"
                  ></div>
                </div>

                <!-- Second Label -->
                <label
                  :class="{'text-white': genreSwitchIsChecked, 'text-gray-500': !genreSwitchIsChecked}"
                  class="ml-3 text-sm transition-colors duration-300"
                >
                  All Genres Like...
                </label>
              </div>

              <div class="flex justify-center py-4">
                <div class="max-h-32 overflow-y-auto border text-sm text-center rounded-lg block p-2.5 bg-gray-700 border-gray-600 placeholder-gray-400 text-white focus:ring-green-500 focus:border-green-500 scrollbar-thin scrollbar-thumb-gray scrollbar-track-dark">
                  <div 
                    v-for="(genre, index) in allPossibleGenres" 
                    :key="index" 
                    :value="genre" 
                    @click="toggleGenre(genre)"
                    :class="{
                      'bg-green-500': genresSelected.includes(genre) && genreSwitchIsChecked,
                      'bg-red-500': genresSelected.includes(genre) && !genreSwitchIsChecked,
                    }"
                    class="cursor-pointer"
                  >
                    {{ genre }}
                  </div>
                </div>
              </div>

              <p class="mt-1 text-center text-white">
                Gameplay Length
              </p>
              <div class="flex flex-wrap whitespace-nowrap justify-center gap-x-6 py-4">
                <div class="flex items-center mt-1">
                  <input 
                    v-model="gameplayLenAll" 
                    @change="gameplayLenZeroToFiveHours = gameplayLenFiveToTwentyHours = gameplayLenTwentyToFiftyHours = gameplayLenOverFiftyHours = false" 
                    type="checkbox" 
                    class="shrink-0 mt-0.5 border-gray-700 rounded text-green-500 focus:ring-green-500 bg-gray-800 checked:bg-green-500 checked:border-green-500 focus:ring-offset-gray-800 custom-checkbox" 
                    id="hs-checkbox-group-1">
                  <label for="hs-checkbox-group-1" class="text-sm text-gray-400 ml-3">All</label>
                </div>

                <div class="flex items-center mt-1">
                  <input 
                    v-model="gameplayLenZeroToFiveHours" 
                    @change="uncheckGameplayLenAll('gameplayLenZeroToFiveHours')" 
                    type="checkbox" 
                    class="shrink-0 mt-0.5 border-gray-700 rounded text-green-500 focus:ring-green-500 bg-gray-800 checked:bg-green-500 checked:border-green-500 focus:ring-offset-gray-800 custom-checkbox" 
                    id="hs-checkbox-group-2"
                  >
                  <label for="hs-checkbox-group-2" class="text-sm text-gray-400 ml-3">0 - 5 hours</label>
                </div>

                <div class="flex items-center mt-1">
                  <input 
                    v-model="gameplayLenFiveToTwentyHours" 
                    @change="uncheckGameplayLenAll('gameplayLenFiveToTwentyHours')" 
                    type="checkbox" 
                    class="shrink-0 mt-0.5 border-gray-700 rounded text-green-500 focus:ring-green-500 bg-gray-800 checked:bg-green-500 checked:border-green-500 focus:ring-offset-gray-800 custom-checkbox" 
                    id="hs-checkbox-group-3">
                  <label for="hs-checkbox-group-3" class="text-sm text-gray-400 ml-3">5 - 20 hours</label>
                </div>

                <div class="flex items-center mt-1">
                  <input 
                    v-model="gameplayLenTwentyToFiftyHours" 
                    @change="uncheckGameplayLenAll('gameplayLenTwentyToFiftyHours')" 
                    type="checkbox" 
                    class="shrink-0 mt-0.5 border-gray-700 rounded text-green-500 focus:ring-green-500 bg-gray-800 checked:bg-green-500 checked:border-green-500 focus:ring-offset-gray-800 custom-checkbox" 
                    id="hs-checkbox-group-4">
                  <label for="hs-checkbox-group-4" class="text-sm text-gray-400 ml-3">20 - 50 hours</label>
                </div>

                <div class="flex items-center mt-1">
                  <input 
                    v-model="gameplayLenOverFiftyHours" 
                    @change="uncheckGameplayLenAll('gameplayLenOverFiftyHours')" 
                    type="checkbox" 
                    class="shrink-0 mt-0.5 border-gray-700 rounded text-green-500 focus:ring-green-500 bg-gray-800 checked:bg-green-500 checked:border-green-500 focus:ring-offset-gray-800 custom-checkbox" 
                    id="hs-checkbox-group-5">
                  <label for="hs-checkbox-group-5" class="text-sm text-gray-400 ml-3">Over 50 hours</label>
                </div>
              </div>

              <p class="mt-1 text-center text-white">
                Game Rating (IGN Score)
              </p>
              <div class="flex flex-wrap whitespace-nowrap justify-center gap-x-3 py-4">
                <div class="flex items-center mt-1">
                  <input 
                    v-model="ignScoresAll" 
                    @change="ignScoresTopRated = ignScoresWorthwhile = ignScoresSubpar = ignScoresNotRated = false" 
                    type="checkbox" 
                    class="shrink-0 mt-0.5 border-gray-700 rounded text-green-500 focus:ring-green-500 bg-gray-800 checked:bg-green-500 checked:border-green-500 focus:ring-offset-gray-800 custom-checkbox" 
                    id="hs-checkbox-group-6">
                  <label for="hs-checkbox-group-6" class="text-sm text-gray-400 ml-3">All</label>
                </div>

                <div class="flex items-center mt-1">
                  <input 
                    v-model="ignScoresTopRated"
                    @change="uncheckIgnScoresAll()" 
                    type="checkbox" 
                    class="shrink-0 mt-0.5 border-gray-700 rounded text-green-500 focus:ring-green-500 bg-gray-800 checked:bg-green-500 checked:border-green-500 focus:ring-offset-gray-800 custom-checkbox" 
                    id="hs-checkbox-group-7">
                  <label for="hs-checkbox-group-7" class="text-sm text-gray-400 ml-3">Top Rated (8-10)</label>
                </div>

                <div class="flex items-center mt-1">
                  <input 
                  v-model="ignScoresWorthwhile" 
                  @change="uncheckIgnScoresAll()" 
                  type="checkbox" 
                  class="shrink-0 mt-0.5 border-gray-700 rounded text-green-500 focus:ring-green-500 bg-gray-800 checked:bg-green-500 checked:border-green-500 focus:ring-offset-gray-800 custom-checkbox" 
                  id="hs-checkbox-group-8">
                  <label for="hs-checkbox-group-8" class="text-sm text-gray-400 ml-3">Worthwhile (5-8)</label>
                </div>

                <div class="flex items-center mt-1">
                  <input 
                  v-model="ignScoresSubpar" 
                  @change="uncheckIgnScoresAll()" 
                  type="checkbox" 
                  class="shrink-0 mt-0.5 border-gray-700 rounded text-green-500 focus:ring-green-500 bg-gray-800 checked:bg-green-500 checked:border-green-500 focus:ring-offset-gray-800 custom-checkbox" 
                  id="hs-checkbox-group-9">
                  <label for="hs-checkbox-group-9" class="text-sm text-gray-400 ml-3">Subpar (1-5)</label>
                </div>

                <div class="flex items-center mt-1">
                  <input 
                  v-model="ignScoresNotRated" 
                  @change="uncheckIgnScoresAll()" 
                  type="checkbox" 
                  class="shrink-0 mt-0.5 border-gray-700 rounded text-green-500 focus:ring-green-500 bg-gray-800 checked:bg-green-500 checked:border-green-500 focus:ring-offset-gray-800 custom-checkbox" 
                  id="hs-checkbox-group-10">
                  <label for="hs-checkbox-group-10" class="text-sm text-gray-400 ml-3">NR - Not Rated</label>
                </div>
              </div>
              <p class="mt-1 text-center text-white">
                Platform(s)
              </p>
              <div class="flex justify-center gap-x-6 py-4">
                <div class="flex items-center mt-1">
                  <input 
                  v-model="platformAll" 
                  @change="platformXbox = platformPc = false" 
                  type="checkbox" 
                  class="shrink-0 mt-0.5 border-gray-700 rounded text-green-500 focus:ring-green-500 bg-gray-800 checked:bg-green-500 checked:border-green-500 focus:ring-offset-gray-800 custom-checkbox" 
                  id="hs-checkbox-group-11">
                  <label for="hs-checkbox-group-11" class="text-sm text-gray-400 ml-3">All</label>
                </div>

                <div class="flex items-center mt-1">
                  <input 
                  v-model="platformXbox" 

                  @change="uncheckPlatformAll('platformXbox')"
                  type="checkbox" 
                  class="shrink-0 mt-0.5 border-gray-700 rounded text-green-500 focus:ring-green-500 bg-gray-800 checked:bg-green-500 checked:border-green-500 focus:ring-offset-gray-800 custom-checkbox" 
                  id="hs-checkbox-group-12">
                  <label for="hs-checkbox-group-12" class="text-sm text-gray-400 ml-3">Xbox</label>
                </div>

                <div class="flex items-center mt-1">
                  <input 
                  v-model="platformPc" 
                  @change="uncheckPlatformAll('platformPc')" 
                  type="checkbox" 
                  class="shrink-0 mt-0.5 border-gray-700 rounded text-green-500 focus:ring-green-500 bg-gray-800 checked:bg-green-500 checked:border-green-500 focus:ring-offset-gray-800 custom-checkbox" 
                  id="hs-checkbox-group-13">
                  <label for="hs-checkbox-group-13" class="text-sm text-gray-400 ml-3">PC</label>
                </div>
              </div>

              <p class="mt-1 text-center text-white">
                Types of Games
              </p>
              <div class="flex flex-wrap justify-center gap-x-3 gap-y-2 py-4">
                <div class="flex items-center mt-1">
                  <input 
                  v-model="typeAll" 
                  @change="typeNewReleases = typeLeavingSoon = typePotentiallyLeavingSoon = typeOther = false" 
                  type="checkbox" 
                  class="shrink-0 mt-0.5 border-gray-700 rounded text-green-500 focus:ring-green-500 bg-gray-800 checked:bg-green-500 checked:border-green-500 focus:ring-offset-gray-800 custom-checkbox" 
                  id="hs-checkbox-group-14">
                  <label for="hs-checkbox-group-14" class="text-sm text-gray-400 ml-3">All</label>
                </div>

                <div class="flex items-center mt-1">
                  <input 
                  v-model="typeNewReleases" 
                  @change="uncheckTypeAll('typeNewReleases')" 
                  type="checkbox" 
                  class="shrink-0 mt-0.5 border-gray-700 rounded text-green-500 focus:ring-green-500 bg-gray-800 checked:bg-green-500 checked:border-green-500 focus:ring-offset-gray-800 custom-checkbox" 
                  id="hs-checkbox-group-15">
                  <label for="hs-checkbox-group-15" class="text-sm text-gray-400 ml-3">New Releases</label>
                </div>

                <div class="flex items-center mt-1">
                  <input 
                  v-model="typeLeavingSoon" 
                  @change="uncheckTypeAll('typeLeavingSoon')" 
                  type="checkbox" 
                  class="shrink-0 mt-0.5 border-gray-700 rounded text-green-500 focus:ring-green-500 bg-gray-800 checked:bg-green-500 checked:border-green-500 focus:ring-offset-gray-800 custom-checkbox" 
                  id="hs-checkbox-group-16">
                  <label for="hs-checkbox-group-16" class="text-sm text-gray-400 ml-3">Leaving Soon</label>
                </div>

                <div class="flex items-center mt-1">
                  <input 
                    v-model="typePotentiallyLeavingSoon" 
                    @change="uncheckTypeAll('typePotentiallyLeavingSoon')" 
                    type="checkbox" 
                    class="shrink-0 mt-0.5 border-gray-700 rounded text-green-500 focus:ring-green-500 bg-gray-800 checked:bg-green-500 checked:border-green-500 focus:ring-offset-gray-800 custom-checkbox" 
                    id="hs-checkbox-group-17">
                  <label for="hs-checkbox-group-17" class="text-sm text-gray-400 ml-3">
                    Potentially Leaving Soon
                    <div class="hs-tooltip inline-block text-white text-xs">
                      <button @mouseover="showTooltip" @mouseout="hideTooltip" type="button" class="hs-tooltip-toggle">
                        <svg class="inline w-4 h-4 mb-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                        </svg>
                        <span ref="tooltipContent" class="hs-tooltip-content opacity-0 transition-opacity inline-block absolute invisible z-10 w-40 py-1 px-2 text-left text-xs font-bold bg-secondary border-green-500" style="border: 1px solid green; background-color: #1a202c; color: white; border-radius: 4px;" role="tooltip">
                          Games that are not yet confirmed to be leaving soon, but have been on the service for a while and are likely to leave in the next few months.
                        </span>
                      </button>
                    </div>
                  </label>
                </div>

                <div class="flex items-center mt-1">
                  <input 
                  v-model="typeOther" 
                  @change="uncheckTypeAll('typeOther')" 
                  type="checkbox" 
                  class="shrink-0 mt-0.5 border-gray-700 rounded text-green-500 focus:ring-green-500 bg-gray-800 checked:bg-green-500 checked:border-green-500 focus:ring-offset-gray-800 custom-checkbox" 
                  id="hs-checkbox-group-18">
                  <label for="hs-checkbox-group-18" class="text-sm text-gray-400 ml-3">Other</label>
                </div>
              </div>

              <p class="mt-1 text-center text-white">
                Preferred Game Properties
              </p>
              
              <div class="flex justify-center items-center mt-2">
                <!-- First Label -->
                <label
                  :class="{'text-white': !propertiesSwitchIsChecked, 'text-gray-500': propertiesSwitchIsChecked}"
                  class="mr-3 text-sm transition-colors duration-300"
                >
                  All Properties Except...
                </label>

                <!-- Toggle Switch -->
                <div @click.prevent="propertiesSwitchIsChecked = !propertiesSwitchIsChecked" class="relative">
                  <input
                    type="checkbox"
                    id="toggleSwitch"
                    class="sr-only peer w-full h-full cursor-pointer"
                    v-model="propertiesSwitchIsChecked"
                  />
                  <div
                    class="block bg-red-500 peer-checked:bg-green-400 w-7 h-4 rounded-full transition-colors duration-300"
                  ></div>
                  <div
                    class="dot absolute left-0.5 top-0.5 bg-white w-3 h-3 rounded-full transition-transform duration-300 ease-in-out peer-checked:transform peer-checked:translate-x-3"
                  ></div>
                </div>

                <!-- Second Label -->
                <label
                  :class="{'text-white': propertiesSwitchIsChecked, 'text-gray-500': !propertiesSwitchIsChecked}"
                  class="ml-3 text-sm transition-colors duration-300"
                >
                  All Properties Like...
                </label>
              </div>

              <div class="flex justify-center py-4">
                <div class="max-h-32 overflow-y-auto border text-sm text-center rounded-lg block p-2.5 bg-gray-700 border-gray-600 placeholder-gray-400 text-white focus:ring-green-500 focus:border-green-500 scrollbar-thin scrollbar-thumb-gray scrollbar-track-dark">
                  <div 
                    v-for="(property, index) in allPossibleProperties" 
                    :key="index" 
                    :value="property" 
                    @click="toggleProperty(property)"
                    :class="{
                      'bg-green-500': propertiesSelected.includes(property) && propertiesSwitchIsChecked,
                      'bg-red-500': propertiesSelected.includes(property) && !propertiesSwitchIsChecked,
                    }"
                    class="cursor-pointer"
                  >
                    {{ property }}
                  </div>
                </div>
              </div>
              
            </div>
            <div class="flex justify-center items-center gap-x-2 py-3 px-4 border-t border-gray-700">
              <button @click="applyPickingPreferences" class="py-3 px-4 inline-flex justify-center items-center gap-2 rounded-md border border-transparent font-semibold bg-primary text-white hover:bg-green-500 focus:outline-none focus:ring-2 focus:ring-offset-2 transition-all text-sm focus:ring-offset-gray-800" href="#" data-hs-overlay="#hs-allgames-config-modal">
                Save changes
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- POP-UP MODAL TO ESTABLISH PICKING SETTINGS (ENDS) -->


      <!-- POP-UP MODAL FOR GAME PROPERTIES (STARTS) -->
      <div id="hs-gameprops-modal-allgames" class="hs-overlay hidden w-full h-full fixed top-0 left-0 z-[60] overflow-x-hidden overflow-y-auto flex justify-center items-center inset-0 bg-black bg-opacity-50 backdrop-filter backdrop-blur-sm">
        <div class="hs-overlay-open:opacity-100 hs-overlay-open:duration-500 opacity-0 transition-all sm:max-w-lg sm:w-full m-3">
          <div class="flex flex-col border shadow-sm rounded-xl bg-gray-800 border-gray-700 shadow-slate-700/[.7]">
            <div class="flex justify-between items-center py-3 px-4 border-b border-gray-700">
              <h3 class="font-bold text-white">
                Game Properties
              </h3>
              <button type="button" class="hs-dropdown-toggle inline-flex flex-shrink-0 justify-center items-center h-8 w-8 rounded-md text-gray-500 hover:text-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 transition-all text-sm focus:ring-gray-700 focus:ring-offset-gray-800" data-hs-overlay="#hs-gameprops-modal-allgames">
                <span class="sr-only">Close</span>
                <svg class="w-3.5 h-3.5" width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0.258206 1.00652C0.351976 0.912791 0.479126 0.860131 0.611706 0.860131C0.744296 0.860131 0.871447 0.912791 0.965207 1.00652L3.61171 3.65302L6.25822 1.00652C6.30432 0.958771 6.35952 0.920671 6.42052 0.894471C6.48152 0.868271 6.54712 0.854471 6.61352 0.853901C6.67992 0.853321 6.74572 0.865971 6.80722 0.891111C6.86862 0.916251 6.92442 0.953381 6.97142 1.00032C7.01832 1.04727 7.05552 1.1031 7.08062 1.16454C7.10572 1.22599 7.11842 1.29183 7.11782 1.35822C7.11722 1.42461 7.10342 1.49022 7.07722 1.55122C7.05102 1.61222 7.01292 1.6674 6.96522 1.71352L4.31871 4.36002L6.96522 7.00648C7.05632 7.10078 7.10672 7.22708 7.10552 7.35818C7.10442 7.48928 7.05182 7.61468 6.95912 7.70738C6.86642 7.80018 6.74102 7.85268 6.60992 7.85388C6.47882 7.85498 6.35252 7.80458 6.25822 7.71348L3.61171 5.06702L0.965207 7.71348C0.870907 7.80458 0.744606 7.85498 0.613506 7.85388C0.482406 7.85268 0.357007 7.80018 0.264297 7.70738C0.171597 7.61468 0.119017 7.48928 0.117877 7.35818C0.116737 7.22708 0.167126 7.10078 0.258206 7.00648L2.90471 4.36002L0.258206 1.71352C0.164476 1.61976 0.111816 1.4926 0.111816 1.36002C0.111816 1.22744 0.164476 1.10028 0.258206 1.00652Z" fill="currentColor"/>
                </svg>
              </button>
            </div>
            <div v-if="gameSelected && gameSelected.game_attributes"  class="p-4 overflow-y-auto">
              <div class="mt-1 text-gray-400" v-for="(attribute, index) in gameSelected.game_attributes" :key="index">
                {{ attribute }}
              </div>
            </div>
            <div v-else>
              <div class="mt-1 text-gray-400">
                No game properties available.
              </div>
            </div>
            <div class="flex justify-end items-center gap-x-2 py-3 px-4 border-t border-gray-700">
              <button type="button" class="hs-dropdown-toggle py-3 px-4 inline-flex justify-center items-center gap-2 rounded-md border font-medium shadow-sm align-middle focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-600 transition-all text-sm bg-slate-900 hover:bg-slate-800 border-gray-700 text-gray-400 hover:text-white focus:ring-offset-gray-800" data-hs-overlay="#hs-gameprops-modal-allgames">
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- POP-UP MODAL FOR GAME PROPERTIES (ENDS) -->

      <FooterComponent />
    </div>
  </body>
</template>





<script setup>
  import axios from 'axios';

  import { computed, onMounted, ref, watch } from 'vue';
  import { useRouter } from 'vue-router';
  import { useStore } from 'vuex';

  import HeaderComponent from '@/components/Layout/HeaderComponent.vue';
  import FooterComponent from '@/components/Layout/FooterComponent.vue';

  // Axios configurations
  axios.defaults.xsrfCookieName = 'csrftoken';
  axios.defaults.xsrfHeaderName = 'X-CSRFToken';
  axios.defaults.withCredentials = true;

  const router = useRouter();
  const store = useStore();

  const loading = ref(true);
  const searchLoading = ref(false);

  const gameTitleSearch = ref(null);

  // Define the ref for the DOM element
  const scrollableDiv = ref(null);

  const loadingImagePlaceholderPath = ref('images/loading-image-placeholder.png')
  const initialLoad = ref(true);

  const userId = ref(null);
  const gameSelected = ref(null);
  const allGames = ref([]);
  const filteredGames = ref([]);
  const filteredAndSearchedGames = ref([]);

  const notificationPresent = ref(false);
  const notificationTimeoutId = ref(null);
  const isPlayingOrPlayedGame = ref(false);
  const notificationLinkPresent = ref(false);
  const notification = ref(null);

  // Game config properties
  const allPossibleGenres = ref([]);
  const genresSelected = ref(['All']);
  const genreSwitchIsChecked = ref(true);

  // Watch for changes in genreSwitchIsChecked
  watch(genreSwitchIsChecked, () => {
    genresSelected.value = ['All'];
  });

  const allPossibleProperties = ref([]);
  const propertiesSelected = ref(['All'])
  const propertiesSwitchIsChecked = ref(true);

  // Watch for changes in propertiesSwitchIsChecked
  watch(propertiesSwitchIsChecked, () => {
    propertiesSelected.value = ['All'];
  });

  const gameplayLenAll = ref(true);
  const gameplayLenZeroToFiveHours = ref(false);
  const gameplayLenFiveToTwentyHours = ref(false);
  const gameplayLenTwentyToFiftyHours = ref(false);
  const gameplayLenOverFiftyHours = ref(false);

  const ignScoresAll = ref(true);
  const ignScoresTopRated = ref(false);
  const ignScoresWorthwhile = ref(false);
  const ignScoresSubpar = ref(false);
  const ignScoresNotRated = ref(false);

  const platformAll = ref(true);
  const platformXbox = ref(false);
  const platformPc = ref(false);

  const typeAll = ref(true);
  const typeNewReleases = ref(false);
  const typeLeavingSoon = ref(false);
  const typePotentiallyLeavingSoon = ref(false);
  const typeOther = ref(false);

  // User quota properties
  const userQuota = ref(null);
  // const premiumMember = ref(false);
  const pickingPreferenceSelected = ref(false);
  const addGameInterestSelected = ref(false);

  // Sorting properties
  const sortCategory = ref('Alphabetical');
  const sortDirection = ref(null);

  // Pagination properties
  const currentPage = ref(1);
  const gamesPerPage = ref(15);

  const totalPages = computed(() => {
    return Math.ceil(filteredAndSearchedGames.value.length / gamesPerPage.value);
  });

  const gamesToDisplay = computed(() => {
    const startIndex = (currentPage.value - 1) * gamesPerPage.value;
    const endIndex = startIndex + gamesPerPage.value;
    return filteredAndSearchedGames.value.slice(startIndex, endIndex);
  });

  const navigateToIgnUrl = (game) => {
    const ignUrl = game.ign_url;

    if (ignUrl) {
      window.open(ignUrl, '_blank');
    } else {
      window.open('https://www.ign.com/', '_blank');
    }
  }

  const navigateToMetacriticUrl = (game) => {
    const searcheableTitle = makeGameNameSearchable(game.title);
    const metacriticUrl = `https://www.metacritic.com/search/${searcheableTitle}/`

    // Browse and open a new window to the metacritic url
    window.open(metacriticUrl, '_blank');
  }

  const navigateToHowLongToBeatUrl = (game) => {
    const searcheableTitle = makeGameNameSearchable(game.title);
    const howLongToBeatUrl = `https://howlongtobeat.com/?q=${searcheableTitle}`

    // Browse and open a new window to the metacritic url
    window.open(howLongToBeatUrl, '_blank');
  }

  const navigateToGamingDashboardPage = () => {
    router.push('/my-games');
  }

  const escapeRegExp = (string) => {
      return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
  }

  const isRecentlyReleased = (game) => {
    if (!game.release_on_gamepass_date) return false;
    const releaseDate = new Date(game.release_on_gamepass_date);
    const today = new Date();
    const timeDifference = today - releaseDate;
    const daysDifference = timeDifference / (1000 * 3600 * 24);
    return daysDifference <= 7;
  }

  // Pagination methods
  const nextPage = () => {
    if (currentPage.value < totalPages.value) currentPage.value++;

    // Scroll the specific div to the top
    if (scrollableDiv.value) {
      scrollableDiv.value.scrollTop = 0;
    }
  }

  const prevPage = () => {
    if (currentPage.value > 1) currentPage.value--;
    
    // Scroll the specific div to the top
    if (scrollableDiv.value) {
      scrollableDiv.value.scrollTop = 0;
    }
  }

  const clearExistingNotification = () => {
    if (notificationTimeoutId.value) {
      clearTimeout(notificationTimeoutId.value);
      notificationTimeoutId.value = null;
      notificationPresent.value = false;
      notificationLinkPresent.value = false;
      isPlayingOrPlayedGame.value = false;
    }
  }

  const showTooltip = (event) => {
      const tooltip = event.currentTarget.querySelector('.hs-tooltip-content');
      tooltip.classList.remove('opacity-0', 'invisible');
      tooltip.classList.add('opacity-100', 'visible');
  }

  const hideTooltip = (event) => {
      const tooltip = event.currentTarget.querySelector('.hs-tooltip-content');
      tooltip.classList.remove('opacity-100', 'visible');
      tooltip.classList.add('opacity-0', 'invisible');
  }

  const makeGameNameSearchable = (gameName) => {
    if (!gameName) {
      return '';
    }

    const elements = [
        "™",
        "®",
        "(Game Preview)",
        "(Xbox Series X|S & PC)",
        "(Xbox Series X|S)",
        "(Xbox One Edition)",
        "(Xbox One)",
        "Game Preview",
        "Xbox Series X|S & PC",
        "Xbox Series X|S",
        "Xbox One Edition",
        "Xbox One"
    ];

    let result = gameName;
    for (let element of elements) {
        const regex = new RegExp(escapeRegExp(element), 'g');
        result = result.replace(regex, "");
    }
    return result.trim();
  }

  const selectGame = (game) => {
    gameSelected.value = game;
  }

  const navigateToLoginPage = () => {
      router.push('/login');
  }

  const uncheckTypeAll = () => {
    typeAll.value = false;
  }

  const uncheckPlatformAll = () => {
    platformAll.value = false;
  }

  const uncheckIgnScoresAll = () => {
    ignScoresAll.value = false;
  }

  const generateGameDetailSlug = (gameTitle) => {
    return gameTitle
      .toLowerCase()                       // Convert to lowercase
      .replace(/[^a-z0-9\s-]/g, '')        // Remove invalid characters
      .trim()                              // Remove leading/trailing spaces
      .replace(/\s+/g, '-')                // Replace spaces with hyphens
      .replace(/-+/g, '-')                 // Remove duplicate hyphens
  }


  const viewGameDetailsPage = async (gameId, gameTitle, event) => {

    // Check if the click target is the inner link or inside it
    if (event.target.closest('a[data-hs-overlay="#hs-gameprops-modal-allgames"]')) {
      return; // Exit if the click originated from the inner link
    }

    const gameDetailSlug = await generateGameDetailSlug(gameTitle);
    router.push(
      { 
        name: 'GameDetailsPage', 
        params: { gameId: gameId, gameTitle: gameDetailSlug } 
      }
    );
  }


  const toggleProperty = (property) => {
    // If "All" is selected, deselect everything else
    if (property === 'All') {
      propertiesSelected.value = propertiesSelected.value.includes('All') ? [] : ['All'];
    } else {
      // If anything else is selected, deselect "All"
      if (propertiesSelected.value.includes('All')) {
        propertiesSelected.value = [];
      }
      if (propertiesSelected.value.includes(property)) {
        propertiesSelected.value = propertiesSelected.value.filter(g => g !== property);
      } else {
        propertiesSelected.value.push(property);
      }
    }
  }

  const toggleGenre = (genre) => {
    // If "All" is selected, deselect everything else
    if (genre === 'All') {
      genresSelected.value = genresSelected.value.includes('All') ? [] : ['All'];
    } else {
      // If anything else is selected, deselect "All"
      if (genresSelected.value.includes('All')) {
        genresSelected.value = [];
      }
      if (genresSelected.value.includes(genre)) {
        genresSelected.value = genresSelected.value.filter(g => g !== genre);
      } else {
        genresSelected.value.push(genre);
      }
    }
  }

  const uncheckGameplayLenAll = () => {
    gameplayLenAll.value = false;
  }

  const sortBy = async (category, direction) => {
    if (!category || !direction) {
      category = sortCategory.value;
      direction = sortDirection.value;
    } else {
      sortCategory.value = category;
      sortDirection.value = direction;
    }

    // First we sort filteredAndSearchedGames in alphabetical order so that the subsequent sorting is consistent
    filteredAndSearchedGames.value = filteredAndSearchedGames.value.sort((a, b) => {
      return a.title.localeCompare(b.title);
    });

    if (category === 'IGN Score') {
      if (direction === 'Low to High') {
        filteredAndSearchedGames.value = filteredAndSearchedGames.value.sort((a, b) => {
          // Check for "NR" and sort them to the end
          if (a.ign_score === "NR") return 1;
          if (b.ign_score === "NR") return -1;
          return parseFloat(a.ign_score) - parseFloat(b.ign_score);
        });
      } else { // Assuming "High to Low"
        filteredAndSearchedGames.value = filteredAndSearchedGames.value.sort((a, b) => {
          // Check for "NR" and sort them to the end
          if (a.ign_score === "NR") return 1;
          if (b.ign_score === "NR") return -1;
          return parseFloat(b.ign_score) - parseFloat(a.ign_score);
        });
      }
    } else if (category === 'Game Length') {
      if (direction === 'Low to High') {
        filteredAndSearchedGames.value = filteredAndSearchedGames.value.sort((a, b) => {
          const aValue = a.ign_duration_hours === "--" || a.ign_duration_hours === null ? Infinity : parseInt(a.ign_duration_hours);
          const bValue = b.ign_duration_hours === "--" || b.ign_duration_hours === null ? Infinity : parseInt(b.ign_duration_hours);
          return aValue - bValue;
        });
      } else {
        filteredAndSearchedGames.value = filteredAndSearchedGames.value.sort((a, b) => {
          const aValue = a.ign_duration_hours === "--" || a.ign_duration_hours === null ? -Infinity : parseInt(a.ign_duration_hours);
          const bValue = b.ign_duration_hours === "--" || b.ign_duration_hours === null ? -Infinity : parseInt(b.ign_duration_hours);
          return bValue - aValue;
        });
      }
    } else if (category === 'Release Year') {

      // Turn original_release_date into a date object
      filteredAndSearchedGames.value = filteredAndSearchedGames.value.map(game => {
        return {
          ...game,
          original_release_date: game.original_release_date ? new Date(game.original_release_date) : null
        };
      });

      if (direction === 'Low to High') {
        filteredAndSearchedGames.value = filteredAndSearchedGames.value.sort((a, b) => {
          // Handle null years by pushing them to the end
          if (a.original_release_date === null) return 1;
          if (b.original_release_date === null) return -1;
          return a.original_release_date - b.original_release_date;
        });
      } else { // Assuming "High to Low"
        filteredAndSearchedGames.value = filteredAndSearchedGames.value.sort((a, b) => {
          // Handle null years by pushing them to the end
          if (a.original_release_date === null) return 1;
          if (b.original_release_date === null) return -1;
          return b.original_release_date - a.original_release_date;
        });
      }
    } else if (category === 'Game Pass Release Date') {
      // Turn release_on_gamepass_date into a date object
      filteredAndSearchedGames.value = filteredAndSearchedGames.value.map(game => {
        return {
          ...game,
          release_on_gamepass_date: game.release_on_gamepass_date ? new Date(game.release_on_gamepass_date) : null
        };
      });

      if (direction === 'Low to High') {
        filteredAndSearchedGames.value = filteredAndSearchedGames.value.sort((a, b) => {
          // Handle null years by pushing them to the end
          if (a.release_on_gamepass_date === null) return 1;
          if (b.release_on_gamepass_date === null) return -1;
          return a.release_on_gamepass_date - b.release_on_gamepass_date;
        });
      } else { // Assuming "High to Low"
        filteredAndSearchedGames.value = filteredAndSearchedGames.value.sort((a, b) => {
          // Handle null years by pushing them to the end
          if (a.release_on_gamepass_date === null) return 1;
          if (b.release_on_gamepass_date === null) return -1;
          return b.release_on_gamepass_date - a.release_on_gamepass_date;
        });
      }
    } else if (category === 'Alphabetical') {
      if (direction === 'Low to High') {
        filteredAndSearchedGames.value = filteredAndSearchedGames.value.sort((a, b) => {
          return a.title.localeCompare(b.title);
        });
      }
    }

    // Go to page 1 again
    currentPage.value = 1;
  }

  const applyPickingPreferences = async () => {
    // Clearing any existing notification
    clearExistingNotification();

    // Used when we display recently released games
    let sortByGamePassReleaseDate = false;

    loading.value = true;
    initialLoad.value = false;


    // We first check if the user has any quota left
    // if (!premiumMember.value && userQuota.value['search_games_pick_preferences_quota'] <= 0) {
    //   notification.value = 'You have no more preference customizations left this week. Upgrade to Premium to get unlimited customizations.';
    //   notificationPresent.value = true;
    //   notificationLinkPresent.value = true;

    //   notificationTimeoutId.value = setTimeout(() => {
    //       notificationPresent.value = false;
    //       notificationLinkPresent.value = false;
    //   }, 3000);

    //   addGameInterestSelected.value = false;
    //   pickingPreferenceSelected.value = true;

    //   loading.value = false;
    //   return;
    // }

    // Make all image_loaded tags false
    filteredGames.value = filteredGames.value.map(game => {
      game.image_loaded = false;
      return game;
    });

    // Making a deep copy of all the games first
    filteredGames.value = JSON.parse(JSON.stringify(allGames.value));


    // Attempting to filter based on genre
    if (genreSwitchIsChecked.value) {
      // We are doing an all genres inclusive search
      if (genresSelected.value.includes('All')) {
        // We pass, as we already have all the games available
      } else {
        // Otherwise, we filter to include the genres selected
        filteredGames.value = filteredGames.value.filter(game => 
          genresSelected.value.some(genre => game.ign_all_possible_genres.includes(genre))
        );
      }
    } else {
      // We are doing an all genres except search
      if (genresSelected.value.includes('All')) {
        // We are excluding all genres
        filteredGames.value = [];
      } else {
        // Otherwise, we filter to exclude the genres selected
        filteredGames.value = filteredGames.value.filter(game => 
          game.ign_all_possible_genres.every(genre => !genresSelected.value.includes(genre))
        );
      }
    }

    // Attempting to filter based on Platform
    if (!platformAll.value) {
      if (platformXbox.value && platformPc.value) {
        filteredGames.value = filteredGames.value.filter(game => game.platforms.includes('Xbox') || game.platforms.includes('PC'));
      } else if (platformXbox.value) {
        filteredGames.value = filteredGames.value.filter(game => game.platforms.includes('Xbox'));
      } else if (platformPc.value) {
        filteredGames.value = filteredGames.value.filter(game => game.platforms.includes('PC'));
      }
    }

    // Attempting to filter based on Gameplay Length
    if (gameplayLenAll.value) {
      // We pass, as we already have all the games available
    } else {
      // We first have to convert the ign duration from text to hours
      filteredGames.value = filteredGames.value.map(game => {
        let gameplayHours = game.ign_duration_hours;
        if (gameplayHours) {
          gameplayHours = gameplayHours.replace(" hrs", "");
        }

        game.ign_duration_hours = parseInt(gameplayHours);
        return game;
      });

      // Filtering games
      // Initialize an array to hold the filtered games for each condition
      let filtered = [];

      // Check each condition independently
      if (gameplayLenZeroToFiveHours.value) {
        filtered = [...filtered, ...filteredGames.value.filter(game => game.ign_duration_hours >= 0 && game.ign_duration_hours < 5)];
      }
      if (gameplayLenFiveToTwentyHours.value) {
        filtered = [...filtered, ...filteredGames.value.filter(game => game.ign_duration_hours >= 5 && game.ign_duration_hours < 20)];
      }
      if (gameplayLenTwentyToFiftyHours.value) {
        filtered = [...filtered, ...filteredGames.value.filter(game => game.ign_duration_hours >= 20 && game.ign_duration_hours < 50)];
      }
      if (gameplayLenOverFiftyHours.value) {
        filtered = [...filtered, ...filteredGames.value.filter(game => game.ign_duration_hours >= 50)];
      }

      // Remove duplicates from the filtered array and assign it back to this.filteredGames
      filteredGames.value = [...new Set(filtered)];

      // Converting the parsed ign duration from integer hours back to text
      filteredGames.value = filteredGames.value.map(game => {
        if (game.ign_duration_hours) {
          game.ign_duration_hours = `${game.ign_duration_hours} hrs`;
        }
        return game;
      });
    }

    // Filter based on IGN Rating
    if (ignScoresAll.value) {
      // We pass, as we already have all the games available
    } else {
      // Attempting to filter based on IGN Scores
      // Initialize an array to hold the filtered games for each condition
      let filtered = [];

      // Check each condition independently
      if (ignScoresTopRated.value) {
        // Filter based on ign_score converted to a float and between 8 and 10
        filtered = [
          ...filtered,
          ...filteredGames.value.filter(game => {
            const score = parseFloat(game.ign_score);
            return !isNaN(score) && score >= 8 && score <= 10;
          })
        ];
      }

      if (ignScoresWorthwhile.value) {
        // Filter based on ign_score converted to a float and between 5 and 8
        filtered = [
          ...filtered,
          ...filteredGames.value.filter(game => {
            const score = parseFloat(game.ign_score);
            return !isNaN(score) && score >= 5 && score < 8;
          })
        ];
      }

      if (ignScoresSubpar.value) {
        // Filter based on ign_score converted to a float and between 1 and 4
        filtered = [
          ...filtered,
          ...filteredGames.value.filter(game => {
            const score = parseFloat(game.ign_score);
            return !isNaN(score) && score >= 0 && score < 5;
          })
        ];
      }

      if (ignScoresNotRated.value) {
        // Filter based on ign_score which is "NR"
        filtered = [
          ...filtered,
          ...filteredGames.value.filter(game => game.ign_score === "NR" || game.ign_score === null)
        ];
      }

      // Remove duplicates from the filtered array and assign it back to this.filteredGames
      filteredGames.value = [...new Set(filtered)];
    }


    if (typeAll.value) {
      // We pass, as we already have all the games available
    } else {
      // Attempting to filter based on Game Type (e.g. New Releases/Leaving Soon)
      // Initialize an array to hold the filtered games for each condition
      let filtered = [];

      // Check each condition independently
      if (typeNewReleases.value) {
        sortByGamePassReleaseDate = true;
        filtered = [...filtered, ...filteredGames.value.filter(game => game.recently_added)];
      }
      if (typeLeavingSoon.value) {
        filtered = [...filtered, ...filteredGames.value.filter(game => game.leaving_soon)];
      }
      if (typePotentiallyLeavingSoon.value) {
        filtered = [...filtered, ...filteredGames.value.filter(game => game.potentially_leaving_soon)];
      }
      if (typeOther.value) {
        filtered = [...filtered, ...filteredGames.value.filter(game => !game.recently_added && !game.leaving_soon)];
      }

      // Remove duplicates from the filtered array and assign it back to this.filteredGames
      filteredGames.value = [...new Set(filtered)];
    }

    // Attempting to filter based on game properties
    if (propertiesSwitchIsChecked.value) {
      // We are doing an all properties inclusive search
      if (propertiesSelected.value.includes('All')) {
        // We pass, as we already have all the games available
      } else {
        // Otherwise, we filter based on the properties selected
        filteredGames.value = filteredGames.value.filter(game => 
          propertiesSelected.value.some(property => game.game_attributes.includes(property))
        );
      }
    } else {
      // We are doing an all properties except search
      if (propertiesSelected.value.includes('All')) {
        // We are excluding all properties
        filteredGames.value = [];
      } else {
        // Otherwise, we filter to exclude the properties selected
        filteredGames.value = filteredGames.value.filter(game => 
          game.game_attributes.every(property => !propertiesSelected.value.includes(property))
        );
      }
    }

    filteredAndSearchedGames.value = filteredGames.value;
    gameTitleSearch.value = null;
    await searchGames(null);

    // Depleting the user's quota
    await depleteQuota('search_games_pick_preferences_quota');

    addGameInterestSelected.value = false;
    pickingPreferenceSelected.value = true;

    if (sortByGamePassReleaseDate) {
      await sortBy('Game Pass Release Date', 'High to Low');
    } else {
      await sortBy(sortCategory.value, sortDirection.value);
    }

    loading.value = false;
    currentPage.value = 1;
  }

  const addGameInterest = async (interestStatus, gameTitle) => {
      // Clearing any existing notification
      clearExistingNotification();

      // If the user has no quota left, we notify them and return
      // if (!premiumMember.value && userQuota.value['game_addition_quota'] === 0) {
      //   notification.value = 'You have no game additions left this week. Upgrade to premium to get unlimited game additions.';
      //   notificationPresent.value = true;
      //   notificationLinkPresent.value = true;

      //   notificationTimeoutId.value = setTimeout(() => {
      //       notificationPresent.value = false;
      //       notificationLinkPresent.value = false;
      //   }, 3000);

      //   pickingPreferenceSelected.value = false;
      //   addGameInterestSelected.value = true;

      //   return;
      // }

      // Checking if the user is adding a playing, played, or completed game
      // This is to see if the user needs to rate the game or not
      if (
        interestStatus === 'currently playing' || 
        interestStatus === 'played'|| 
        interestStatus === 'completed') {
        isPlayingOrPlayedGame.value = true;
        selectGame(gameTitle);
      }

      // For notifications where the user needs to rate the game, we increase
      // the timeout
      const timeoutDurationSeconds = isPlayingOrPlayedGame.value ? 6 : 2;

      // Define the payload data
      const inputData = {
        game_title: gameTitle,
        status: interestStatus
      };

      // Make the API call
      try {
        await axios.post(`${process.env.VUE_APP_BACKEND_API_ROUTE}/game-interest/`, inputData,
          {
            headers: {
                'Content-Type': 'application/json'
            },
            withCredentials: true,
          })

          if (interestStatus === 'uninterested') {
            notification.value = 'Game has been removed from your preferences and lists successfully';
          } else if (interestStatus === 'wishlist') {
            notification.value = 'Game has been added to your "Interested" list successfully';
          } else if (interestStatus === 'currently playing') {
            notification.value = 'Game has been added to your "Currently Playing" list successfully';
          } else if (interestStatus === 'played') {
            notification.value = 'Game has been added to your "Played" list successfully';
          } else if (interestStatus === 'completed') {
            notification.value = 'Game has been added to your "Completed" list successfully';
          }

          // Notifying user that the game has been added to their list
          notificationPresent.value = true;

          notificationTimeoutId.value = setTimeout(() => {
              notificationPresent.value = false;
              isPlayingOrPlayedGame.value = false;
          }, timeoutDurationSeconds * 1000);

          // Depleting the user's quota
          await depleteQuota('game_addition_quota');

          pickingPreferenceSelected.value = false;
          addGameInterestSelected.value = true;

      } catch (error) {
        console.error('There was an error!', error);
      }

      const scrollPosition = window.scrollY;
      window.scrollTo(0, scrollPosition);
  }


  const depleteQuota = async (attemptType) => {
    const inputData = {
      attempt_type: attemptType
    };

    // Make the API call
    try {
      await axios.post(`${process.env.VUE_APP_BACKEND_API_ROUTE}/quota-deplete/`, inputData,
        {
          headers: {
              'Content-Type': 'application/json',
              // 'X-CSRFToken': csrfToken
          },
          withCredentials: true,
        })

      // We also deplete the quota in the front-end
      if (attemptType === 'game_addition_quota') {
        userQuota.value['game_addition_quota'] -= 1;
      } else if (attemptType === 'search_games_pick_preferences_quota') {
        userQuota.value['search_games_pick_preferences_quota'] -= 1;
      }
    } catch (error) {
      console.error('Could not deplete the quota for the user.', error);
    }
  }


  const getAllGamesInitially = async () => {

      loading.value = true;
      searchLoading.value = true;

      initialLoad.value = true;

      // Retrieving all games
      const allGamesRes = require('../assets/data/games-info-all.json');
      let interimAllGames = allGamesRes['data']

      // For each game at a property called image_loaded to false
      interimAllGames = interimAllGames.map(game => {
        game.image_loaded = false;
        return game;
      });

      // Add backup URLs for the game videos if they are not available
      interimAllGames = interimAllGames.map(game => {
        if (!game.youtube_review_url) {
          game.youtube_review_url = `https://www.youtube.com/results?search_query=${game.title}+review`;
        }

        if (!game.youtube_trailer_url) {
          game.youtube_trailer_url = `https://www.youtube.com/results?search_query=${game.title}+trailer`;
        }

        if (!game.youtube_walkthrough_url) {
          game.youtube_walkthrough_url = `https://www.youtube.com/results?search_query=${game.title}+full+walkthrough`;
        }

        if (!game.microsoft_store_url) {
          game.microsoft_store_url = `https://www.xbox.com/en-us/games/store/${game.title}/${game.xbox_api_id}`;
        }
        return game;
      });

      // Sort the games by their game title in alphabetical order before adding them to the lists
      if (interimAllGames.length > 0) {
        interimAllGames.sort((a, b) => (a.title > b.title) ? 1 : -1);
        allGames.value = interimAllGames;
        filteredGames.value = interimAllGames;
        filteredAndSearchedGames.value = interimAllGames;
      }

      loading.value = false;
      searchLoading.value = false;
  }

    const searchGames = async (game_title_search) => {

      // Clearing any existing notification
      clearExistingNotification();

      loading.value = true;
      searchLoading.value = true;

      initialLoad.value = false;

      // Make all image_loaded tags false
      filteredAndSearchedGames.value = filteredAndSearchedGames.value.map(game => {
        game.image_loaded = false;
        return game;
      });

      // Retrieving all games that are searchable based on our configurations
      let interimAllGames = filteredGames.value

      // Filtering the games based on the search criteria
      if (game_title_search) {
        interimAllGames = interimAllGames.filter(game => game.title.toLowerCase().includes(game_title_search.toLowerCase()));
      }

      // Sort the games by their game title in alphabetical order before adding them to the lists
      if (interimAllGames.length > 0) {
        interimAllGames.sort((a, b) => (a.title > b.title) ? 1 : -1);
        filteredAndSearchedGames.value = interimAllGames;        
      } else {
          filteredAndSearchedGames.value = [];
      }

      loading.value = false;
      searchLoading.value = false;
      currentPage.value = 1;

      // Depleting the user's quota
      await depleteQuota('search_games_quota');
  }

  const constructUniqueGameProperties = () => {
    // Extracting our unique game properties from our dataset
    const allGameProperties = allGames.value.reduce(
      (acc, game) => [...acc, ...game['game_attributes']], []
    );
    const uniqueGameProperties = [...new Set(allGameProperties)];

    const initialGamePropertiesToPickOrder = [
      "All",
      "Cloud Gaming Enabled",
      "Single Player",
      "Online Multiplayer (Xbox Live)",
      "Local Multiplayer (Xbox Live)",
      "Local Multiplayer",
      "Online Co-op Support",
      "Online Co-op (Xbox Live)",
      "Local Co-op (Xbox Live)",
      "Local Co-op Support",
      "Shared Split-Screen",
      "Cross-Platform Multiplayer (Xbox Live)",
      "Cross-Platform Co-op (Xbox Live)",
      "Optimized for Xbox Series X/S",
      "4K Resolution Support",
      "Cross-Generation Play (Console)",
      "Cross-Generation Multiplayer (Xbox Live)",
      "Online Multiplayer (Gold Required)",
      "Xbox Live Enabled",
      "Achievements (Xbox Live)",
      "Game Streaming Support",
      "Broadcasting Support",
      "Keyboard and Mouse Support (Console)",
      "Gamepad Support (PC)",
      "120 FPS Support",
      "60 FPS Support",
      "Ray Tracing Support",
      "HDR Support",
      "VRR Support (Variable Refresh Rate)",
      "Xbox Enhanced Features",
      "DTS:X Audio Support",
      "Dolby Atmos Support",
      "Spatial Sound Support",
      "Xbox Play Anywhere",
      "Cloud Saves (Xbox Live)",
      "Xbox Live Clubs",
      "Xbox Live Presence"
    ];

    const restOfGameProperties = uniqueGameProperties.filter(gameProperty => !initialGamePropertiesToPickOrder.includes(gameProperty));
  
    // Sort restOfGameProperties in alphabetical order
    restOfGameProperties.sort((a, b) => a.localeCompare(b));

    const properlyOrderedGamePropertiesList = initialGamePropertiesToPickOrder.concat(restOfGameProperties);

    allPossibleProperties.value = properlyOrderedGamePropertiesList;
  }

  const constructUniqueGenres = () => {
    // Extracting our unique genres from our dataset
    const allGenres = allGames.value.reduce(
      (acc, game) => [...acc, ...game['ign_all_possible_genres']], []
    );
    const uniqueGenres = [...new Set(allGenres)];

    const initialGenresToPickOrder = [
      "All",
      "Shooter",
      "Action",
      "Adventure",
      "RPG",
      "Simulation",
      "Battle Royale",
      "Sports",
      "Racing",
      "Strategy",
      "Puzzle",
      "Fighting"
    ]

    const restOfGenres = uniqueGenres.filter(genre => !initialGenresToPickOrder.includes(genre));

    // Sort the restOfGenres in alphabetical order
    restOfGenres.sort((a, b) => a.localeCompare(b));

    const properlyOrderedGenresList = initialGenresToPickOrder.concat(restOfGenres);

    allPossibleGenres.value = properlyOrderedGenresList;
  }

  // const checkAndResetSubscriptionQuota = async () => {

  //   // Initialize a time-zone aware date
  //   const userTimezoneDatetime = new Date().toISOString();

  //   const inputData = {
  //     user_tz_aware_current_datetime: userTimezoneDatetime
  //   };

  //   // Make the API call
  //   try {
  //     await axios.post(`${process.env.VUE_APP_BACKEND_API_ROUTE}/check-and-reset-subscription-quota/`, inputData,
  //       {
  //         headers: {
  //             'Content-Type': 'application/json',
  //             // 'X-CSRFToken': csrfToken
  //         },
  //         withCredentials: true,
  //       })
  //   } catch (error) {
  //     console.error('Could not check and reset the subscription quota for the user.', error);
  //   }
  // }

  const getUserSubscriptionQuota = async () => {
    // Make the API call
    try {
      const response = await axios.get(`${process.env.VUE_APP_BACKEND_API_ROUTE}/get-user-subscription-quota/`,
        {
          headers: {
              'Content-Type': 'application/json',
              // 'X-CSRFToken': csrfToken
          },
          withCredentials: true,
        })

      if (response.data) {
        userQuota.value = response.data;
        // premiumMember.value = (userQuota.value['subscription_type'] !== 'free');
      }

    } catch (error) {
      console.error('Could not retrieve the subscription quota for the user.', error);
    }
  }

  const rateGame = async (gameTitle, newRating) => {

      if (newRating < 0 || newRating > 5 || isNaN(newRating)) {
        alert('Please enter a rating between 0 and 5.');
        return;
      }

      // Define the payload data
      const inputData = {
        game_title: gameTitle,
        rating: newRating
      };

      // Make the API call
      try {
        const response = await axios.post(`${process.env.VUE_APP_BACKEND_API_ROUTE}/rate-game/`, inputData,
          {
            headers: {
                'Content-Type': 'application/json',
                // 'X-CSRFToken': csrfToken
            },
            withCredentials: true,
          })

        if (response.data) {
          await store.dispatch('setGameKarmaPoints', response.data.points_balance);
        }

      } catch (error) {
        console.error('There was an error!', error);
      }

      // Wait for a brief moment (half a second) to show the user that their 
      // rating was successful
      await new Promise(r => setTimeout(r, 250));

      // We clear any present notifications, with a brief API call delay, to
      // show user on mobile screens that their rating was successful
      clearExistingNotification();

      // Deplete the user's quota
      await depleteQuota('game_ratings_quota');
  }

  onMounted(async () => {

    // Retrieving the user
    try {
      const response = await fetch(`${process.env.VUE_APP_BACKEND_API_ROUTE}/user`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        },
        credentials: 'include'  // Necessary for passing of login cookies from backend
      })

      // Check for 403 error
      if (response.status === 403) {
        await store.dispatch('setAuth', false);
        await navigateToLoginPage();
      }

      const content = await response.json();

      if (content.detail === 'Unauthenticated!') {
        await store.dispatch('setAuth', false);
        await navigateToLoginPage();
      } else {
        await store.dispatch('setAuth', true);
        userId.value = content.id;
      }
    } catch (e) {
      await store.dispatch('setAuth', false);
      await navigateToLoginPage();
    }

    await getAllGamesInitially();
    await constructUniqueGameProperties();
    await constructUniqueGenres();

    // Next we get check and reset the user's game picking quota
    // await checkAndResetSubscriptionQuota();

    // We next get the user's leftover quota
    await getUserSubscriptionQuota();
  })

  const authenticated = computed(() => store.state.authenticated);

</script>

<style scoped>

  .custom-checkbox {
    -webkit-appearance: none; /* Removes default checkbox appearance in WebKit-based browsers */
    appearance: none; /* Removes default checkbox appearance */
    width: 1.25rem; /* Add custom width */
    height: 1.25rem; /* Add custom height */
    background-color: #1F2937; /* Tailwind's bg-gray-800 color */
    border: 1px solid #4B5563; /* Tailwind's border-gray-700 color */
    border-radius: 0.25rem; /* Tailwind's rounded class */

    /* Apply custom styles when the checkbox is checked */
    display: inline-block;
    position: relative;
  }

  .custom-checkbox:checked {
    background-color: #42b983;
    border-color: #42b983;
  }

  .custom-checkbox:checked::before {
    content: '✔'; /* Add a checkmark */
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: white; /* Checkmark color */
    font-size: 1rem; /* Adjust size as needed */
    text-align: center;
    line-height: 1.25rem; /* Center the checkmark */
  }

  .hs-tooltip-content {
    transition: opacity 0.3s ease-in-out;
  }

  .hs-tooltip-content.opacity-0 {
    opacity: 0;
    visibility: hidden;
  }
  
  .hs-tooltip-content.opacity-100 {
    opacity: 1;
    visibility: visible;
  }

  .ign-score-container {
    position: relative;
    text-align: center;
    color: white;
  }

  .ign-score {
    position: absolute;
    font-weight: bolder;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -35%);
  }

  /* These styles are applied by default.
    They will also override the hover
    style for all stars to the right
    of the currently hovered star. */
  .rate i, .rate i:hover ~ i {
    color: gray;
    text-shadow: none;
    transition: color 200ms,
                text-shadow 200ms;
    /* This will remove the delay when
      moving the cursor left or right
      within the set of stars. */
    transition-delay: 0;
  }

  /* This is the style that will be
    applied to all stars and then
    subsequently removed from the stars
    to the right of the one being
    hovered. */
  .rate:hover i {
    color: #fc0;
    text-shadow: #fc0 0 0 20px;
  }

  /* Make the effect apply one star at a
    time. Limiting the selector to when
    .rate is hovered removes the effect
    without delay when cursor leaves
    the .rate area. */
  .rate:hover i:nth-child(2) {
    transition-delay: 30ms;
  }

  .rate:hover i:nth-child(3) {
    transition-delay: 60ms;
  }

  .rate:hover i:nth-child(4) {
    transition-delay: 90ms;
  }

  .rate:hover i:nth-child(5) {
    transition-delay: 120ms;
  }

  /* Miscellaneous styles. */
  .rate i {
    cursor: pointer;
    font-style: normal;
  }

</style>

