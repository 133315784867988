<template>

  <body class="bg-slate-900 min-h-full">

    <div class="max-w-[50rem] flex flex-col mx-auto w-full min-h-screen">

      <HeaderComponent />

      <!-- ========== MAIN CONTENT ========== -->
      <main role="main" class="">
        <div class="grid place-items-center px-4 sm:px-6 lg:px-8">
          <div class="max-w-lg w-full">
            <div class="mb-3 text-left">
              <button class="text-left font-bold mr-3 disabled cursor-auto">View</button>
              <button type="button" 
                      :class="[
                        'game-list-btn-selector game-list-btn-selector-left py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-l-lg border bg-secondary text-white focus:outline-none disabled:opacity-50 disabled:pointer-events-none',
                        sectionShowing === 'Details' ? 'bg-primary' : 'bg-secondary']">
                Details
              </button>
              <button @click="browseToGameReviews(gameChosen['id'], gameChosen['title'])" 
                      type="button" 
                      :class="[
                        'game-list-btn-selector game-list-btn-selector-middle py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium border border-transparent text-white focus:outline-none disabled:opacity-50 disabled:pointer-events-none',
                        sectionShowing === 'Reviews' ? 'bg-primary' : 'bg-secondary']">
                Reviews
              </button>
              <button @click="browseToGameTips(gameChosen['id'], gameChosen['title'])" 
                      type="button" 
                      :class="[
                        'game-list-btn-selector game-list-btn-selector-right py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-r-lg border border-transparent text-white focus:outline-none disabled:opacity-50 disabled:pointer-events-none',
                        sectionShowing === 'Tips' ? 'bg-primary' : 'bg-secondary']">
                Tips
              </button>
            </div>

            <div v-if="loading" class="max-w-lg w-full mt-32">
              <div class="animate-spin inline-block size-6 w-10 h-10 border-[3px] border-current border-t-transparent rounded-full text-white" role="status" aria-label="loading">
                <span class="sr-only">Loading...</span>
              </div>
            </div>

            <div v-else class="max-w-lg border shadow-sm rounded-xl hover:shadow-lg transition bg-gray-800 border-gray-700 shadow-slate-700/[.7]">
              <div v-if="this.gameChosen && this.gameChosen['images'].length > 0" class="bg-gray-700 rounded-t-xl">
                <div>
                  <div v-for="(image, index) in this.gameChosen['images']" :key="index" class="carousel-item relative" v-show="currentGameImageShown === index">
                    <button @click="prevGameImageShown" type="button" class="absolute bottom-2 left-2 inline-flex flex-shrink-0 justify-center items-center opacity-60 gap-2 h-[3rem] w-[3rem] rounded-full border border-transparent bg-primary text-white hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-offset-2 transition-all text-lg font-bold focus:ring-offset-gray-800">
                      <font-awesome-icon icon="fa-solid fa-chevron-left" />
                    </button>
                    <button @click="prevGameImageShown" type="button" class="absolute bottom-2 right-2 inline-flex flex-shrink-0 justify-center items-center opacity-60 gap-2 h-[3rem] w-[3rem] rounded-full border border-transparent bg-primary text-white hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-offset-2 transition-all text-lg font-bold focus:ring-offset-gray-800">
                      <font-awesome-icon icon="fa-solid fa-chevron-right" />
                    </button>
                    <img class="w-full h-auto aspect-video rounded-t-xl object-contain" :src="image" alt="An image showing the game">
                  </div>
                </div>
              </div>
              <div v-else>
                <img class="w-full h-auto aspect-video rounded-t-xl" src="../assets/mystery-game.png" alt="Mystery placeholder box for the game">
              </div>
              <div class="px-4 md:px-5">
                <div>
                  <div v-if="this.gameChosen && this.gameChosen['title']" >
                    <div class="mt-3 text-xl font-bold text-white h-12">
                      {{ this.gameChosen['title'] }}
                    </div>
                    <div v-if="gameChosen['leavingSoon']" class="text-primary font-bold underline">
                      Leaving {{ this.gameChosen['leavingSoonDate'] }}
                    </div>
                    <div v-if="isRecentlyReleased(gameChosen)" class="text-xs text-primary font-bold underline pb-1">
                      Just Released
                    </div>
                    <div v-if="gameChosen['leftGamepass']" class="text-xs text-primary font-bold underline pb-1">
                      Left Game Pass
                    </div>
                    <div class="flex justify-between text-white text-left">
                      <span class="font-medium py-2">IGN Score</span>
                      <div class="ign-score-container">
                        <img src="../assets/ign-score-background.png" alt="IGN score logo which is a red hexagon">
                        <div class="ign-score">{{ this.gameChosen['ignScore'] }}</div>
                      </div>
                    </div>
                    <div class="flex justify-between text-white text-left">
                      <span class="font-medium">Gameplay Hours</span>
                      <span>{{ this.gameChosen['ignDurationHours'] }}</span>
                    </div>
                    <div class="flex justify-between text-white text-left py-2`">
                      <span class="font-medium">Genres&nbsp;</span> 
                      <span>{{ this.gameChosen['ignAllPossibleGenres'].join(', ') }}</span>
                    </div>
                    <div class="flex justify-between text-white text-left">
                      <span class="font-medium">Links&nbsp;</span>
                      <span v-if="isLargeScreen" class="mt-1">
                        <a class="hover:underline" :href="metacriticUrl" target="_blank">Metacritic</a>, 
                        <a class="hover:underline" :href="howLongToBeatUrl" target="_blank">HowLongToBeat</a>, 
                        <a class="hover:underline" :href="this.gameChosen['ignUrl']" target="_blank">IGN</a>, 
                        <a data-hs-overlay="#hs-gameprops-modal" class="cursor-pointer hover:underline" target="_blank">Game Properties</a>,
                      </span> 
                      <span v-else class="text-xs mt-1">
                        <a class="hover:underline" :href="metacriticUrl" target="_blank">Metacritic</a>, 
                        <a class="hover:underline" :href="howLongToBeatUrl" target="_blank">HowLongToBeat</a>, 
                        <a class="hover:underline" :href="this.gameChosen['ignUrl']" target="_blank">IGN</a>, 
                        <a data-hs-overlay="#hs-gameprops-modal" class="cursor-pointer hover:underline" target="_blank">Game Properties</a>,
                        <a class="hover:underline" :href="this.gameChosen['microsoftStoreUrl']" target="_blank">
                          Save to <font-awesome-icon 
                            class="text-white px-1 hover:text-green-300 cursor-pointer" 
                            :icon="['fab', 'xbox']" 
                          />
                        </a> |
                        <a class="hover:underline" :href="this.gameChosen['youtubeReviewUrl']" target="_blank">Review</a>,
                        <a class="hover:underline" :href="this.gameChosen['youtubeTrailerUrl']" target="_blank">Trailer</a>,
                        <a class="hover:underline" :href="this.gameChosen['youtubeWalkthroughUrl']" target="_blank">Walkthrough</a>
                      </span>
                    </div>
                    <div class="flex justify-start text-white text-right pb-2">
                      <span v-if="isLargeScreen">
                        <a class="hover:underline" :href="this.gameChosen['microsoftStoreUrl']" target="_blank">
                          Save to <font-awesome-icon 
                            class="text-white px-1 hover:text-green-300 cursor-pointer" 
                            :icon="['fab', 'xbox']" 
                          />
                        </a> |
                        <a class="hover:underline" :href="this.gameChosen['youtubeReviewUrl']" target="_blank">Review Video</a>,
                        <a class="hover:underline" :href="this.gameChosen['youtubeTrailerUrl']" target="_blank">Trailer Video</a>,
                        <a class="hover:underline" :href="this.gameChosen['youtubeWalkthroughUrl']" target="_blank">Walkthrough Video</a>
                      </span>
                    </div>
                  </div>
                  <div v-else>
                    <div class="mt-3 text-xl font-bold text-white h-12">
                      Mystery Game Awaits You
                    </div>
                    <div class="flex justify-between text-white text-left">
                      <span class="font-medium py-2">IGN Score</span>
                      <div class="ign-score-container">
                        <img src="../assets/ign-score-background.png" alt="IGN score logo which is a red hexagon">
                        <div class="ign-score">?</div>
                      </div>
                    </div>
                    <div class="flex justify-between text-white text-left">
                      <span class="font-medium">Gameplay Hours</span>
                      <span>? hrs</span>
                    </div>
                    <div class="flex justify-between text-white text-left py-2">
                      <span class="font-medium">Genres&nbsp;</span> 
                      <span>?&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    </div>
                    <div class="flex justify-between text-white text-left pb-5">
                      <span class="font-medium">Links&nbsp;</span> 
                      <span>?&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    </div>
                  </div>
                </div>
                <div>
                  <p v-if="this.gameChosen && this.gameChosen['description']" class="h-28 mt-1 pr-2 text-gray-400 overflow-auto text-left scrollbar-thin scrollbar-thumb-gray scrollbar-track-dark">
                    <span class="text-white">Released in {{ this.gameChosen['originalReleaseYear'] }} - </span>
                    <span>{{ this.gameChosen['description'] }}</span>
                  </p>
                  <p v-else class="h-28 mt-1 pr-2 text-gray-400 overflow-auto text-left">
                    Select the button below to pick a random game from the full Xbox Game Pass selection (Console/PC).
                  </p>
                </div>
                <div>
                  <!-- POP-UP MODAL FOR GAME PROPERTIES (STARTS) -->
                  <div id="hs-gameprops-modal" class="hs-overlay hidden w-full h-full fixed top-0 left-0 z-[60] overflow-x-hidden overflow-y-auto flex justify-center items-center inset-0 bg-black bg-opacity-50 backdrop-filter backdrop-blur-sm">
                    <div class="hs-overlay-open:opacity-100 hs-overlay-open:duration-500 opacity-0 transition-all sm:max-w-lg sm:w-full m-3">
                      <div class="flex flex-col border shadow-sm rounded-xl bg-gray-800 border-gray-700 shadow-slate-700/[.7]">
                        <div class="flex justify-between items-center py-3 px-4 border-b border-gray-700">
                          <h3 class="font-bold text-white">
                            Game Properties
                          </h3>
                          <button type="button" class="hs-dropdown-toggle inline-flex flex-shrink-0 justify-center items-center h-8 w-8 rounded-md text-gray-500 hover:text-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 transition-all text-sm focus:ring-gray-700 focus:ring-offset-gray-800" data-hs-overlay="#hs-gameprops-modal">
                            <span class="sr-only">Close</span>
                            <svg class="w-3.5 h-3.5" width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M0.258206 1.00652C0.351976 0.912791 0.479126 0.860131 0.611706 0.860131C0.744296 0.860131 0.871447 0.912791 0.965207 1.00652L3.61171 3.65302L6.25822 1.00652C6.30432 0.958771 6.35952 0.920671 6.42052 0.894471C6.48152 0.868271 6.54712 0.854471 6.61352 0.853901C6.67992 0.853321 6.74572 0.865971 6.80722 0.891111C6.86862 0.916251 6.92442 0.953381 6.97142 1.00032C7.01832 1.04727 7.05552 1.1031 7.08062 1.16454C7.10572 1.22599 7.11842 1.29183 7.11782 1.35822C7.11722 1.42461 7.10342 1.49022 7.07722 1.55122C7.05102 1.61222 7.01292 1.6674 6.96522 1.71352L4.31871 4.36002L6.96522 7.00648C7.05632 7.10078 7.10672 7.22708 7.10552 7.35818C7.10442 7.48928 7.05182 7.61468 6.95912 7.70738C6.86642 7.80018 6.74102 7.85268 6.60992 7.85388C6.47882 7.85498 6.35252 7.80458 6.25822 7.71348L3.61171 5.06702L0.965207 7.71348C0.870907 7.80458 0.744606 7.85498 0.613506 7.85388C0.482406 7.85268 0.357007 7.80018 0.264297 7.70738C0.171597 7.61468 0.119017 7.48928 0.117877 7.35818C0.116737 7.22708 0.167126 7.10078 0.258206 7.00648L2.90471 4.36002L0.258206 1.71352C0.164476 1.61976 0.111816 1.4926 0.111816 1.36002C0.111816 1.22744 0.164476 1.10028 0.258206 1.00652Z" fill="currentColor"/>
                            </svg>
                          </button>
                        </div>
                        <div class="p-4 overflow-y-auto">
                          <div v-if="this.gameChosen && this.gameChosen['gameAttributes']"  class="p-4 overflow-y-auto">
                            <div class="mt-1 text-gray-400" v-for="(attribute, index) in this.gameChosen['gameAttributes']" :key="index">
                              {{ attribute }}
                            </div>
                          </div>
                          <div v-else>
                            <div class="mt-1 text-gray-400">
                              No game properties available.
                            </div>
                          </div>
                        </div>
                        <div class="flex justify-end items-center gap-x-2 py-3 px-4 border-t border-gray-700">
                          <button type="button" class="hs-dropdown-toggle py-3 px-4 inline-flex justify-center items-center gap-2 rounded-md border font-medium shadow-sm align-middle focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-600 transition-all text-sm bg-slate-900 hover:bg-slate-800 border-gray-700 text-gray-400 hover:text-white focus:ring-offset-gray-800" data-hs-overlay="#hs-gameprops-modal">
                            Close
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- POP-UP MODAL FOR GAME PROPERTIES (ENDS) -->

                </div>
                <div class="flex justify-between items-start my-4" v-if="isLargeScreen">
                  <div class="flex flex-col space-y-2">
                    <button v-if="this.authenticated && this.gameChosen" @click="this.addGameInterest('wishlist')" type="button" class="px-4 text-center gap-2 rounded-md border border-gray-400 font-semibold text-white hover:bg-green-500 focus:outline-none focus:ring-2 focus:ring-offset-2 transition-all text-xs p-1 sm:p-2 ring-offset-gray-800">
                      <font-awesome-icon class="text-white px-1" icon="fa-solid fa-gift" />&nbsp;&nbsp;Interested
                    </button>
                    <button v-if="!this.authenticated && this.gameChosen" @click="this.navigateToSignUpPage()" type="button" class="px-4 text-center items-center gap-2 rounded-md border border-gray-400 font-semibold text-white hover:bg-green-500 focus:outline-none focus:ring-2 focus:ring-offset-2 transition-all text-xs p-1 sm:p-2 ring-offset-gray-800">
                      <font-awesome-icon class="text-white px-1" icon="fa-solid fa-gift" />&nbsp;&nbsp;Interested
                    </button>
                    <button v-if="this.authenticated && this.gameChosen" @click="this.addGameInterest('uninterested')" type="button" class="px-4 inline-flex justify-center items-center gap-2 rounded-md border border-gray-400 font-semibold text-white hover:bg-green-500 focus:outline-none focus:ring-2 focus:ring-offset-2 transition-all text-xs p-1 sm:p-2 ring-offset-gray-800">
                      <font-awesome-icon class="text-white px-1" icon="fa-solid fa-trash" />Uninterested&nbsp;
                    </button>
                    <button v-if="!this.authenticated && this.gameChosen" @click="this.navigateToSignUpPage()" type="button" class="px-4 inline-flex justify-center items-center gap-2 rounded-md border border-gray-400 font-semibold text-white hover:bg-green-500 focus:outline-none focus:ring-2 focus:ring-offset-2 transition-all text-xs p-1 sm:p-2 ring-offset-gray-800">
                      <font-awesome-icon class="text-white px-1" icon="fa-solid fa-trash" />Uninterested&nbsp;
                    </button>
                  </div>

                  <div class="flex flex-col space-y-2 items-center my-4">
                    <button v-if="this.authenticated && this.gameChosen" @click="this.addGameInterest('currently playing')" type="button" class="px-4 inline-flex justify-center items-center gap-2 rounded-md border border-gray-400 font-semibold text-white hover:bg-green-500 focus:outline-none focus:ring-2 focus:ring-offset-2 transition-all text-xs p-1 sm:p-2 ring-offset-gray-800">
                      <font-awesome-icon class="text-white px-1" icon="fa-solid fa-gamepad" />Playing&nbsp;
                    </button>
                    <button v-if="!this.authenticated && this.gameChosen" @click="this.navigateToSignUpPage()" type="button" class="px-4 inline-flex justify-center items-center gap-2 rounded-md border border-gray-400 font-semibold text-white hover:bg-green-500 focus:outline-none focus:ring-2 focus:ring-offset-2 transition-all text-xs p-1 sm:p-2 ring-offset-gray-800">
                      <font-awesome-icon class="text-white px-1" icon="fa-solid fa-gamepad" />Playing&nbsp;
                    </button>
                  </div>

                  <div class="flex flex-col space-y-2">
                    <button v-if="this.authenticated && this.gameChosen" @click="this.addGameInterest('played')" type="button" class="px-4 text-center gap-2 rounded-md border border-gray-400 font-semibold text-white hover:bg-green-500 focus:outline-none focus:ring-2 focus:ring-offset-2 transition-all text-xs p-1 sm:p-2 ring-offset-gray-800">
                      Played&nbsp;&nbsp;<font-awesome-icon class="text-white px-1" icon="fa-solid fa-hourglass-half" />
                    </button>
                    <button v-if="!this.authenticated && this.gameChosen" @click="this.navigateToSignUpPage()" type="button" class="px-4 text-center gap-2 rounded-md border border-gray-400 font-semibold text-white hover:bg-green-500 focus:outline-none focus:ring-2 focus:ring-offset-2 transition-all text-xs p-1 sm:p-2 ring-offset-gray-800">
                      Played&nbsp;&nbsp;<font-awesome-icon class="text-white px-1" icon="fa-solid fa-hourglass-half" />
                    </button>
                    <button v-if="this.authenticated && this.gameChosen" @click="this.addGameInterest('completed')" type="button" class="px-4 text-right gap-2 rounded-md border border-gray-400 font-semibold text-white hover:bg-green-500 focus:outline-none focus:ring-2 focus:ring-offset-2 transition-all text-xs p-1 sm:p-2 ring-offset-gray-800">
                      Completed&nbsp;&nbsp;<font-awesome-icon class="text-white px-1" icon="fa-solid fa-check-to-slot" />
                    </button>
                    <button v-if="!this.authenticated && this.gameChosen" @click="this.navigateToSignUpPage()" type="button" class="px-4 text-right gap-2 rounded-md border border-gray-400 font-semibold text-white hover:bg-green-500 focus:outline-none focus:ring-2 focus:ring-offset-2 transition-all text-xs p-1 sm:p-2 ring-offset-gray-800">
                      Completed&nbsp;&nbsp;<font-awesome-icon class="text-white px-1" icon="fa-solid fa-check-to-slot" />
                    </button>
                  </div>
                </div>
                <div class="my-2" v-else>


                  <div class="flex flex-row justify-center mb-2">
                    <div class="flex flex-col">
                      <button v-if="this.authenticated && this.gameChosen" @click="this.addGameInterest('wishlist')" type="button" class="px-4 mx-2 mb-2 inline-flex justify-center items-center gap-2 rounded-md border border-gray-400 font-semibold text-white focus:outline-none focus:ring-2 focus:ring-offset-2 transition-all text-xs p-1 sm:p-2 ring-offset-gray-800">
                        Interested
                      </button>
                      <button v-if="!this.authenticated && this.gameChosen" @click="this.navigateToSignUpPage()" type="button" class="px-4 mx-2 mb-2 inline-flex justify-center items-center gap-2 rounded-md border border-gray-400 font-semibold text-white focus:outline-none focus:ring-2 focus:ring-offset-2 transition-all text-xs p-1 sm:p-2 ring-offset-gray-800">
                        Interested
                      </button>
                    </div>
                    <div class="flex flex-col">
                      <button v-if="this.authenticated && this.gameChosen" @click="this.addGameInterest('uninterested')" type="button" class="px-2 mx-2 mb-2 inline-flex justify-center items-center gap-2 rounded-md border border-gray-400 font-semibold text-white focus:outline-none focus:ring-2 focus:ring-offset-2 transition-all text-xs p-1 sm:p-2 ring-offset-gray-800">
                        Uninterested
                      </button>
                      <button v-if="!this.authenticated && this.gameChosen" @click="this.navigateToSignUpPage()" type="button" class="px-2 mx-2 mb-2 inline-flex justify-center items-center gap-2 rounded-md border border-gray-400 font-semibold text-white focus:outline-none focus:ring-2 focus:ring-offset-2 transition-all text-xs p-1 sm:p-2 ring-offset-gray-800">
                        Uninterested
                      </button>
                    </div>
                  </div>

                  <div class="flex flex-row justify-center">
                    <div class="flex flex-col">
                      <button v-if="this.authenticated && this.gameChosen" @click="this.addGameInterest('currently playing')" type="button" class="px-4 mx-2 mb-2 inline-flex justify-center items-center gap-2 rounded-md border border-gray-400 font-semibold text-white focus:outline-none focus:ring-2 focus:ring-offset-2 transition-all text-xs p-1 sm:p-2 ring-offset-gray-800">
                        Playing
                      </button>
                      <button v-if="!this.authenticated && this.gameChosen" @click="this.navigateToSignUpPage()" type="button" class="px-4 mx-2 mb-2 inline-flex justify-center items-center gap-2 rounded-md border border-gray-400 font-semibold text-white focus:outline-none focus:ring-2 focus:ring-offset-2 transition-all text-xs p-1 sm:p-2 ring-offset-gray-800">
                        Playing
                      </button>
                    </div>
                    <div class="flex flex-col">
                      <button v-if="this.authenticated && this.gameChosen" @click="this.addGameInterest('played')" type="button" class="px-2 mx-2 mb-2 inline-flex justify-center items-center gap-2 rounded-md border border-gray-400 font-semibold text-white focus:outline-none focus:ring-2 focus:ring-offset-2 transition-all text-xs p-1 sm:p-2 ring-offset-gray-800">
                        Played
                      </button>
                      <button v-if="!this.authenticated && this.gameChosen" @click="this.navigateToSignUpPage()" type="button" class="px-2 mx-2 mb-2 inline-flex justify-center items-center gap-2 rounded-md border border-gray-400 font-semibold text-white focus:outline-none focus:ring-2 focus:ring-offset-2 transition-all text-xs p-1 sm:p-2 ring-offset-gray-800">
                        Played
                      </button>
                    </div>
                    <div class="flex flex-col">
                      <button v-if="this.authenticated && this.gameChosen" @click="this.addGameInterest('completed')" type="button" class="px-2 mx-2 mb-2 inline-flex justify-center items-center gap-2 rounded-md border border-gray-400 font-semibold text-white focus:outline-none focus:ring-2 focus:ring-offset-2 transition-all text-xs p-1 sm:p-2 ring-offset-gray-800">
                        Completed
                      </button>
                      <button v-if="!this.authenticated && this.gameChosen" @click="this.navigateToSignUpPage()" type="button" class="px-2 mx-2 mb-2 inline-flex justify-center items-center gap-2 rounded-md border border-gray-400 font-semibold text-white focus:outline-none focus:ring-2 focus:ring-offset-2 transition-all text-xs p-1 sm:p-2 ring-offset-gray-800">
                        Completed
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <!-- ========== END MAIN CONTENT ========== -->

      <!-- NOTIFICATION (STARTS) -->
      <div v-if="notificationPresent" class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 max-w-xs border rounded-xl shadow-lg bg-gray-800 border-primary-nowhite z-50" role="alert">
        <div class="p-4 relative">
          <button @click="notificationPresent = false" class="absolute top-2 right-2 text-gray-200 hover:text-white">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
          <div class="mb-2 text-center flex justify-center items-center">
            <svg class="flex-shrink-0 h-4 w-4 text-primary mt-0.5" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
            </svg>
          </div>
          <div class="ms-3">
            <p class="text-lg text-left text-gray-200 pl-2">{{ notification }}</p>
            <div v-if="isPlayingOrPlayedGame && !notificationLinkPresent" class="mt-3">
              <hr />
              <div class="mt-2 text-lg font-bold">What would you rate this game?</div>
              <div class="flex justify-center align-center mt-2">
                <span class="rate">
                  <i @click="rateGame(gameChosen['title'], 1)">★</i>
                  <i @click="rateGame(gameChosen['title'], 2)">★</i>
                  <i @click="rateGame(gameChosen['title'], 3)">★</i>
                  <i @click="rateGame(gameChosen['title'], 4)">★</i>
                  <i @click="rateGame(gameChosen['title'], 5)">★</i>
                </span>
              </div>
            </div>
            <div v-if="notificationLinkPresent" class="mt-2 text-lg text-left text-gray-200 pl-2">
              Add your GamerTag from the <router-link to="/recommendations-for-self" class="hover:underline">AI-Recs</router-link> page
            </div>
          </div>
        </div>
      </div>
      <!-- NOTIFICATION (ENDS) -->
       
      <FooterComponent />
    </div>
  </body>


</template>

<script>
import axios from 'axios';

import FooterComponent from './Layout/FooterComponent.vue';
import HeaderComponent from './Layout/HeaderComponent.vue';

// Axios configurations
axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFToken';
axios.defaults.withCredentials = true;


export default {
  name: 'GameDetailsPage',
  data: function() {
    return {
      addGameInterestSelected: false,
      gameChosen: null,
      allGames: [],
      loading: false,
      isPlayingOrPlayedGame: false,
      currentGameImageShown: 0,
      authenticated: false,
      user_id: null,
      userQuota: null,
      notificationPresent: false,
      notification: 'Game has been added to your list successfully',
      notificationLinkPresent: false,
      notificationTimeoutId: null,
      isLargeScreen: false,
      sectionShowing: 'Details'
    }
  },
  props: {
    gameId: {
      type: String,
      required: true
    },
    gameTitle: {
      type: String,
      required: true
    }
  },
  components: {
    HeaderComponent,
    FooterComponent,
  },
  beforeMount: function() {
    this.initializeData();
  },
  computed: {
    metacriticUrl() {
      return `https://www.metacritic.com/search/${this.gameChosen['searchableTitle']}/`;
    },
    howLongToBeatUrl() {
      return `https://howlongtobeat.com/?q=${this.gameChosen['searchableTitle']}`;
    },
  },
  methods: {
    initializeData: async function() {
      this.loading = true;

      // First we check the screen size
      await this.checkScreenSize();

      // Setting up all the games from our dataset
      const allGames = await require('../assets/data/games-info-all.json');
      this.allGames = allGames['data'];

      const gamePicked = this.allGames.find(game => game['xbox_api_id'] === this.gameId);

      const backupMicrosoftStoreUrl = `https://www.xbox.com/en-us/games/store/${gamePicked['title']}/${gamePicked['xbox_api_id']}`
      const backupYoutubeReviewUrl = `https://www.youtube.com/results?search_query=${gamePicked['title']}+review`;
      const backupYoutubeTrailerUrl = `https://www.youtube.com/results?search_query=${gamePicked['title']}+trailer`;
      const backupYoutubeWalkthroughUrl = `https://www.youtube.com/results?search_query=${gamePicked['title']}+full+walkthrough`;

      this.gameChosen = {
        'title': gamePicked['title'] ? gamePicked['title'] : 'NA',
        'searchableTitle': this.makeGameNameSearchable(gamePicked['title']),
        'description': gamePicked['short_description'] ? gamePicked['short_description'] : 'Description not available.',
        'images': gamePicked['image_uris'] ? gamePicked['image_uris'] : 'NA',
        'developerName': gamePicked['developer_name'] ? gamePicked['developer_name'] : 'NA',
        'platforms': gamePicked['platforms'] ? gamePicked['platforms'] : 'NA',
        'ignScore': gamePicked['ign_score'] ? gamePicked['ign_score'] : 'NA',
        'ignDurationHours': gamePicked['ign_duration_hours'] ? gamePicked['ign_duration_hours'] : 'NA',
        'ignAllPossibleGenres': gamePicked['ign_all_possible_genres'] ? gamePicked['ign_all_possible_genres'] : 'NA',
        'ignUrl': gamePicked['ign_url'] ? gamePicked['ign_url'] : 'https://www.ign.com/',
        'microsoftStoreUrl': gamePicked['microsoft_store_url'] ? gamePicked['microsoft_store_url'] : backupMicrosoftStoreUrl,
        'leavingSoon': gamePicked['leaving_soon'],
        'leavingSoonDate': gamePicked['leaving_soon_date'] ? gamePicked['leaving_soon_date'] : 'Soon',
        'leftGamepass': gamePicked['left_gamepass'],
        'originalReleaseYear': gamePicked['original_release_year'] ? gamePicked['original_release_year'] : '--',
        'releaseOnGamePassDate': gamePicked['release_on_gamepass_date'] ? gamePicked['release_on_gamepass_date'] : null,
        'youtubeReviewUrl': gamePicked['youtube_review_url'] ? gamePicked['youtube_review_url'] : backupYoutubeReviewUrl,
        'youtubeTrailerUrl': gamePicked['youtube_trailer_url'] ? gamePicked['youtube_trailer_url'] : backupYoutubeTrailerUrl,
        'youtubeWalkthroughUrl': gamePicked['youtube_walkthrough_url'] ? gamePicked['youtube_walkthrough_url'] : backupYoutubeWalkthroughUrl,
        'gameAttributes': gamePicked['game_attributes'],
        'xboxApiId': gamePicked['xbox_api_id'] ? gamePicked['xbox_api_id'] : 'NA'
      }

      // Attempting to get user information in case they are logged in
      await this.getUserLoginStatus();

      this.loading = false;
    },
    generateGameDetailSlug: function (gameTitle) {
      return gameTitle
        .toLowerCase()                       // Convert to lowercase
        .replace(/[^a-z0-9\s-]/g, '')        // Remove invalid characters
        .trim()                              // Remove leading/trailing spaces
        .replace(/\s+/g, '-')                // Replace spaces with hyphens
        .replace(/-+/g, '-')                 // Remove duplicate hyphens
    },
    browseToGameReviews: async function (gameId, gameTitle) {
      const gameDetailSlug = await this.generateGameDetailSlug(gameTitle);
      this.$router.push(
        { 
          name: 'GameReviewsPage', 
          params: { gameId: gameId, gameTitle: gameDetailSlug } 
        }
      );
    },
    browseToGameTips: async function (gameId, gameTitle) {
      const gameDetailSlug = await this.generateGameDetailSlug(gameTitle);
      this.$router.push(
        { 
          name: 'GameTipsPage', 
          params: { gameId: gameId, gameTitle: gameDetailSlug } 
        }
      );
    },
    getUserLoginStatus: async function () {
      try {
        const response = await fetch(`${process.env.VUE_APP_BACKEND_API_ROUTE}/user`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          },
          credentials: 'include'  // Necessary for passing of login cookies from backend
        })

        const content = await response.json();

        if (content.detail === 'Unauthenticated!' || content.detail === 'Authentication credentials were not provided.') {
          this.authenticated = false;
        } else {
          this.authenticated = true;
          this.user_id = content.id;
        }
      } catch (e) {
        this.authenticated = false;
      }

    },
    nextGameImageShown() {
      if (this.currentGameImageShown < this.gameChosen['images'].length - 1) {
        this.currentGameImageShown++;
      } else {
        this.currentGameImageShown = 0;
      }
    },
    prevGameImageShown() {
      if (this.currentGameImageShown > 0) {
        this.currentGameImageShown--;
      } else {
        this.currentGameImageShown = this.gameChosen['images'].length - 1;
      }
    },
    escapeRegExp(string) {
        return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
    },
    isRecentlyReleased: function (game) {
      if (!game.releaseOnGamePassDate) return false;
      const releaseDate = new Date(game.releaseOnGamePassDate);
      const today = new Date();
      const timeDifference = today - releaseDate;
      const daysDifference = timeDifference / (1000 * 3600 * 24);
      return daysDifference <= 7;
    },
    makeGameNameSearchable: function (gameName) {
      if (!gameName) {
        return '';
      }

      const elements = [
          "™",
          "®",
          "(Game Preview)",
          "(Xbox Series X|S & PC)",
          "(Xbox Series X|S)",
          "(Xbox One Edition)",
          "(Xbox One)",
          "Game Preview",
          "Xbox Series X|S & PC",
          "Xbox Series X|S",
          "Xbox One Edition",
          "Xbox One"
      ];

      let result = gameName;
      for (let element of elements) {
          const regex = new RegExp(this.escapeRegExp(element), 'g');
          result = result.replace(regex, "");
      }
      return result.trim();
    },
    navigateToAboutPage: function() {
      this.$router.push('/about');
    },
    navigateToSignUpPage: function() {
      this.$router.push('/signup');
    },
    navigateToLoginPage: function() {
      this.$router.push('/login');
    },
    navigateToGamePickerPage: function() {
      this.$router.push('/');
    },
    checkScreenSize() {
      this.isLargeScreen = window.innerWidth > 768; // Example breakpoint
    },
    clearExistingNotification() {
      if (this.notificationTimeoutId) {
        clearTimeout(this.notificationTimeoutId);
        this.notificationTimeoutId = null;
        this.notificationPresent = false;
        this.notificationLinkPresent = false;
        this.isPlayingOrPlayedGame = false;
      }
    },
    addGameInterest: function(interest_status) {
      // Clearing any existing notification
      this.clearExistingNotification();

      if (!this.authenticated) {
        console.log('You must be logged in to add a game to your list of interests.');
        return;
      }

      if (
        interest_status === 'currently playing' || 
        interest_status === 'played'|| 
        interest_status === 'completed') {
        this.isPlayingOrPlayedGame = true;
      }

      // For notifications where the user needs to rate the game, we increase
      // the timeout
      const timeoutDurationSeconds = this.isPlayingOrPlayedGame ? 6 : 2;

      // Define the payload data
      const inputData = {
        game_title: this.gameChosen['title'],
        status: interest_status
      };

      // Make the API call
      axios.post(`${process.env.VUE_APP_BACKEND_API_ROUTE}/game-interest/`, inputData,
        {
          headers: {
            'Content-Type': 'application/json'
          },
          withCredentials: true
        })
        .then(response => {
          // Handle success, maybe provide feedback to the user or update the UI
          console.log('Successfully changed game interest', response.data);

          if (interest_status === 'uninterested') {
            this.notification = 'Game has been removed from your preferences and lists successfully';
          } else if (interest_status === 'wishlist') {
            this.notification = 'Game has been added to your "Interested" list successfully';
          } else if (interest_status === 'currently playing') {
            this.notification = 'Game has been added to your "Currently Playing" list successfully';
          } else if (interest_status === 'played') {
            this.notification = 'Game has been added to your "Played" list successfully';
          } else if (interest_status === 'completed') {
            this.notification = 'Game has been added to your "Completed" list successfully';
          }

          // Notifying user that the game has been added to their list
          this.notificationPresent = true;

          this.notificationTimeoutId = setTimeout(() => {
              this.notificationPresent = false;
              this.isPlayingOrPlayedGame = false;
          }, timeoutDurationSeconds * 1000);

          // Depleting the user's quota
          this.depleteQuota('game_addition_quota')
          
          this.pickingPreferenceSelected = false;
          this.addGameInterestSelected = true;
        })
        .catch(error => {
          // Handle error, maybe show an error message to the user
          console.error('There was an error!', error);
        });
    },
    depleteQuota: async function (attemptType) {
      // Depleting the user's quota only applies
      // if the user is authenticated
      if (!this.authenticated) {
        return;
      }

      const inputData = {
        attempt_type: attemptType
      };

      // Make the API call
      try {
        await axios.post(`${process.env.VUE_APP_BACKEND_API_ROUTE}/quota-deplete/`, inputData,
          {
            headers: {
                'Content-Type': 'application/json',
                // 'X-CSRFToken': csrfToken
            },
            withCredentials: true,
          })

      } catch (error) {
        console.error('Could not deplete the quota for the user.', error);
      }
    },
    showTooltip(event) {
      const tooltip = event.currentTarget.querySelector('.hs-tooltip-content');
      tooltip.classList.remove('opacity-0', 'invisible');
      tooltip.classList.add('opacity-100', 'visible');
    },
    hideTooltip(event) {
      const tooltip = event.currentTarget.querySelector('.hs-tooltip-content');
      tooltip.classList.remove('opacity-100', 'visible');
      tooltip.classList.add('opacity-0', 'invisible');
    },
    rateGame: async function(gameTitle, newRating) {
      
      if (newRating < 0 || newRating > 5 || isNaN(newRating)) {
        alert('Please enter a rating between 0 and 5.');
        return;
      }

      // Define the payload data
      const inputData = {
        game_title: gameTitle,
        rating: newRating
      };

      // Make the API call
      try {
        const response = await axios.post(`${process.env.VUE_APP_BACKEND_API_ROUTE}/rate-game/`, inputData,
          {
            headers: {
                'Content-Type': 'application/json',
                // 'X-CSRFToken': csrfToken
            },
            withCredentials: true,
          })

          if (response.data) {
            await this.$store.dispatch('setGameKarmaPoints', response.data.points_balance);
          }
          
      } catch (error) {
        console.error('There was an error!', error);
      }

      // Wait for a brief moment (half a second) to show the user that their 
      // rating was successful
      await new Promise(r => setTimeout(r, 250));

      // We clear any present notifications, with a brief API call delay, to
      // show user on mobile screens that their rating was successful
      this.clearExistingNotification();

      // Deplete the user's quota
      await this.depleteQuota('game_ratings_quota');
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  h3 {
    margin: 40px 0 0;
  }
  ul {
    list-style-type: none;
    padding: 0;
  }
  li {
    display: inline-block;
    margin: 0 10px;
  }
  a {
    color: #42b983;
  }

  .game-list-btn-selector {
    border-top: 2px solid #42b983; 
    border-bottom: 2px solid #42b983; 
    border-left: 1px solid #42b983; 
    border-right: 1px solid #42b983; 
    color: inherit; 
  }

  .game-list-btn-selector-middle {
      border-left: 1px solid #42b983; 
      border-right: 1px solid #42b983;
      color: inherit; 
  }

  .game-list-btn-selector-left {
      border-left: 2px solid #42b983; 
      color: inherit; 
  }

  .game-list-btn-selector-right {
      border-right: 2px solid #42b983; 
      color: inherit; 
  }

  .game-list-btn-selector:hover {
      background-color: #42b983; 
  }

  .game-list-btn-selector:focus {
      background-color: #42b983; 
      outline: none; 
  }

  .hs-tooltip-content {
  transition: opacity 0.3s ease-in-out;
  }

  .hs-tooltip-content.opacity-0 {
    opacity: 0;
    visibility: hidden;
  }

  .hs-tooltip-content.opacity-100 {
    opacity: 1;
    visibility: visible;
  }

  .ign-score-container {
    position: relative;
    text-align: center;
    color: white;
  }

  .ign-score {
    position: absolute;
    font-weight: bolder;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -45%);
  }

  /* These styles are applied by default.
    They will also override the hover
    style for all stars to the right
    of the currently hovered star. */
  .rate i, .rate i:hover ~ i {
    color: gray;
    text-shadow: none;
    transition: color 200ms,
                text-shadow 200ms;
    /* This will remove the delay when
      moving the cursor left or right
      within the set of stars. */
    transition-delay: 0;
  }

  /* This is the style that will be
    applied to all stars and then
    subsequently removed from the stars
    to the right of the one being
    hovered. */
  .rate:hover i {
    color: #fc0;
    text-shadow: #fc0 0 0 20px;
  }

  /* Make the effect apply one star at a
    time. Limiting the selector to when
    .rate is hovered removes the effect
    without delay when cursor leaves
    the .rate area. */
  .rate:hover i:nth-child(2) {
    transition-delay: 30ms;
  }

  .rate:hover i:nth-child(3) {
    transition-delay: 60ms;
  }

  .rate:hover i:nth-child(4) {
    transition-delay: 90ms;
  }

  .rate:hover i:nth-child(5) {
    transition-delay: 120ms;
  }

  /* Miscellaneous styles. */
  .rate i {
    cursor: pointer;
    font-style: normal;
  }

  .rated i {
    color: gray;
    text-shadow: none;
    font-style: normal;
  }

  .starred {
    color: #fc0 !important;
    text-shadow: #fc0 0 0 20px !important;
  }

</style>
