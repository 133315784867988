<script setup>
  import axios from 'axios';

  import { onMounted, ref } from 'vue';
  import { useRouter } from 'vue-router';
  import { useStore } from 'vuex';

  import HeaderComponent from '@/components/Layout/HeaderComponent.vue';
  import FooterComponent from '@/components/Layout/FooterComponent.vue';

  axios.defaults.withCredentials = true;


  const router = useRouter();
  const store = useStore();

  const loading = ref(true);
  const allCompetitions = ref([]);

  const notification = ref('');
  const notificationPresent = ref(false);
  const notificationLinkPresent = ref(false);

  const getAllCompetitions = async () => {
    try {
      const response = await axios.get(`${process.env.VUE_APP_BACKEND_API_ROUTE}/get-all-competitions`);

      if (response.status === 200) {
        allCompetitions.value = response.data['all_competitions'];
        console.log('allCompetitions:', allCompetitions.value);
      } else {
        notification.value = 'Error fetching competitions. Please try again later.';
        notificationPresent.value = true;
      }
    } catch (e) {
      console.error('Error fetching competitions:', e);
    }
  };
  
  const navigateToCompetition = async (urlSlug) => {
    router.push(`/competitions/${urlSlug}`);
  }

  const navigateToSignUpPage = async (intent) => {
    await store.dispatch('setIntent', intent);
    router.push('/signup');
  }

  const computeDaysLeft = (endDate) => {
    const today = new Date();
    const end = new Date(endDate);
    
    // Calculate difference in time
    const differenceInTime = end - today;

    // Calculate difference in days
    const differenceInDays = Math.ceil(differenceInTime / (1000 * 60 * 60 * 24));

    return differenceInDays > 0 ? differenceInDays : 0; // Ensure it doesn't show negative numbers
  }

  const joinCompetition = async (competitionId, competitionUrlSlug) => {

    const gamerId = store.state.gamerId

    if (!gamerId) {
        notification.value = 'You need to have an Xbox GamerTag in order to join this competition.';
        notificationLinkPresent.value = true;
        notificationPresent.value = true;
        return;
    }

    // Define the payload data
    const inputData = {
      competition_id: competitionId
    };

    notification.value = 'You have successfully joined the competition. Good luck! Redirecting you to the competition page in a few seconds.';
    notificationPresent.value = true;

    // Make the API call
    try {
      await axios.post(
        `${process.env.VUE_APP_BACKEND_API_ROUTE}/join-competition/`, 
        inputData,
        {
          headers: {
              'Content-Type': 'application/json'
          },
          withCredentials: true,
        }
      )
      
      router.push(`/competitions/${competitionUrlSlug}`);

    } catch (error) {
      console.error(
        'There was an error trying to join the competition!', 
        error
      );

      notificationPresent.value = false;
      notification.value = 'Sorry but something went wrong in joining the competition. Check and change your Xbox privacy settings. Your profile and gaming history must be publicly visible to join - You can find instructions on this here - https://www.trueachievements.com/privacy-settings';
      notificationPresent.value = true;
    }

  }

  onMounted(() => {
    loading.value = true;

    getAllCompetitions();

    loading.value = false;
  })



</script>

<template>
  <body class="bg-slate-900 min-h-full">
    <div class="max-w-[50rem] flex flex-col mx-auto w-full min-h-screen">
      <HeaderComponent />
      <div>
        <h1 class="text-xl font-bold">Competitions</h1>
        <div v-if="!loading" class="h-[90vh] overflow-y-auto scrollbar-thin scrollbar-thumb-gray scrollbar-track-dark">
          <div v-for="competition in allCompetitions" :key=competition.id class="flex flex-col space-y-4">
            <div class="text-left bg-slate-800 p-4 rounded-lg my-1 cursor-pointer">
              <img :src=competition.image_url alt="competition image" class="rounded-lg">
              <h2 class="font-bold mt-2 break-words">{{ competition.title }}</h2>
              <div class="my-2 break-words">{{ competition.description }}</div>
              <div><font-awesome-icon class="text-amber-300" :icon="['fas', 'trophy']" /> <span class="text-primary font-bold">Chance to Win: </span>{{ competition.chance_to_win_info }}</div>
              <div v-if="competition.user_has_joined" class="text-center my-8">
                <div class="text-primary text-xl font-bold">You Joined This Competition</div>
                <div>[check your placement and chance to win on the leaderboard]</div>
              </div>
              <div class="my-4 text-center">
                  <button v-if="!store.state.authenticated" @click="navigateToSignUpPage('competitions')" type="button" class="px-4 inline-flex justify-center items-center rounded-md border border-transparent font-semibold bg-primary text-white hover:bg-green-500 focus:outline-none focus:ring-2 focus:ring-offset-2 transition-all text-sm p-1 sm:p-3 ring-offset-gray-800">
                    Join Competition
                  </button>
                  <button v-else-if="!competition.user_has_joined" @click="joinCompetition(competition.id, competition.url_slug)" type="button" class="px-4 inline-flex justify-center items-center rounded-md border border-transparent font-semibold bg-primary text-white hover:bg-green-500 focus:outline-none focus:ring-2 focus:ring-offset-2 transition-all text-sm p-1 sm:p-3 ring-offset-gray-800">
                      Join Competition
                  </button>
                  <button v-else-if="competition.user_has_joined" @click="navigateToCompetition(competition.url_slug)" type="button" class="mx-1 inline-flex justify-center items-center rounded-md border border-primary font-semibold text-white focus:outline-none focus:ring-2 focus:ring-offset-2 transition-all text-sm p-2 ring-offset-gray-800">
                      Check Results
                  </button>
                </div>
              <div class="flex items-center justify-between mt-2">
                <div class="text-gray-300 text-xs text-left">Number of Participants: {{ competition.num_participants }}</div>
                <div class="text-gray-300 text-xs text-right">{{ computeDaysLeft(competition.end_date) }} days left</div>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="flex justify-center items-center mt-10">
            <svg class="animate-spin h-10 w-10 text-primary" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
              <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V4a10 10 0 00-10 10h2zm2 8a8 8 0 018-8h2a10 10 0 00-10-10v2zm8 2a8 8 0 01-8-8H4a10 10 0 0010 10v-2z"></path>
            </svg>
          </div>
        </div>
      </div>
      <FooterComponent />
    </div>

      <!-- NOTIFICATION (STARTS) -->
      <div v-if="notificationPresent" class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 max-w-xs border rounded-xl shadow-lg bg-gray-800 border-primary-nowhite z-50" role="alert">
        <div class="p-4 relative">
          <button @click="notificationPresent = false" class="absolute top-2 right-2 text-gray-200 hover:text-white">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
          <div class="mb-2 text-center flex justify-center items-center">
            <svg class="flex-shrink-0 h-4 w-4 text-primary mt-0.5" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
            </svg>
          </div>
          <div class="ms-3">
            <p class="text-lg text-left text-gray-200 break-words overflow-hidden pl-2">{{ notification }}</p>
          </div>
          <div v-if="notificationLinkPresent" class="mt-2 text-lg text-left text-gray-200 pl-2">
              Add your Xbox GamerTag from the <router-link to="/recommendations-for-self" class="text-primary underline">AI-Recs</router-link> page
            </div>
        </div>
      </div>
      <!-- NOTIFICATION (ENDS) -->

  </body>
</template>

<style scoped>

</style>
